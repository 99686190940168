<template>
  <div style="width: 100%; background-color: none;">
    <div class="display_center_x" v-bind:style="{width: width + '%'}">
      <div style="display: flex;">

        <div style="width: 45%">
          <a v-bind:href="google_form_url" target="_blank">
            <img v-bind:src="btn_signup_n" class="pres" />
          </a>
        </div>

        <div style="width: 10%"></div>

        <div style="width: 45%">
          <router-link style="text-decoration: none;" to="/contact">
           <img v-bind:src="btn_contact_n" class="pres" />
          </router-link>
        </div>


      </div>
    </div>
  </div>
</template>

<script>
export default{
    name: 'button_component',
    props: {
	width: Number,
        type: Number,
    },
    data(){
	return{
	    google_form_url: 'https://docs.google.com/forms/d/e/1FAIpQLSd7TewbjxWRCk86wsMTGUY8cCWbGZ1GtLfhLCunsxaGqPGpJQ/viewform?usp=sf_link',
	    btn_signup_n: require('@/assets/images/btn_signup_.png'),
	    btn_contact_n: require('@/assets/images/btn_contact_.png'),
	}
    },
    mounted(){
    },
}
</script>

<style>
</style>
