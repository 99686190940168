<template>
<div class="bg scroll_hidden" v-bind:style="{position: 'relative', width: '100%', height: window_height + 'px', zIndex: 0}">

  <ins class="arenamainId"
  data-iframe_src="https://arenamain.id"
  data-clientkey="eyJ0b3VjaHBvaW50X2lkIjo1LCJwYXJ0bmVyX2lkIjo2LCJwcm9wZXJ0aWVzX2lkIjozfQ==">
  </ins>

  <ins class="arenamainId"
  data-iframe_src="https://arenamain.id/game/tembak"
  data-clientkey="eyJ0b3VjaHBvaW50X2lkIjo3LCJwYXJ0bmVyX2lkIjo2LCJwcm9wZXJ0aWVzX2lkIjozfQ==">
  </ins>
  
  <ins class="arenamainId"
  data-iframe_src="https://arenamain.id/game/meteor"
  data-clientkey="eyJ0b3VjaHBvaW50X2lkIjo4LCJwYXJ0bmVyX2lkIjo2LCJwcm9wZXJ0aWVzX2lkIjozfQ==">
  </ins>

  <ins class="arenamainId"
  data-iframe_src="https://arenamain.id/game/perang-galaksi"
  data-clientkey="eyJ0b3VjaHBvaW50X2lkIjo5LCJwYXJ0bmVyX2lkIjo2LCJwcm9wZXJ0aWVzX2lkIjozfQ==">
  </ins>

  <ins class="arenamainId"
  data-iframe_src="https://arenamain.id/game/tank-tempur"
  data-clientkey="eyJ0b3VjaHBvaW50X2lkIjoxMCwicGFydG5lcl9pZCI6NiwicHJvcGVydGllc19pZCI6M30=">
  </ins>

  <ins class="arenamainId"
  data-iframe_src="https://arenamain.id/game/puzzle-kubus"
  data-clientkey="eyJ0b3VjaHBvaW50X2lkIjo1LCJwYXJ0bmVyX2lkIjo2LCJwcm9wZXJ0aWVzX2lkIjozfQ==">
  </ins>
  
  <ins class="arenamainId"
  data-iframe_src="https://arenamain.id/game/jungle-2048"
  data-clientkey="eyJ0b3VjaHBvaW50X2lkIjo1LCJwYXJ0bmVyX2lkIjo2LCJwcm9wZXJ0aWVzX2lkIjozfQ==">
  </ins>

  <ins class="arenamainId"
  data-iframe_src="https://arenamain.id/game/tuang-sewarna"
  data-clientkey="eyJ0b3VjaHBvaW50X2lkIjo1LCJwYXJ0bmVyX2lkIjo2LCJwcm9wZXJ0aWVzX2lkIjozfQ==">
  </ins>

  <ins class="arenamainId"
  data-iframe_src="https://arenamain.id/game/cat-dekor"
  data-clientkey="eyJ0b3VjaHBvaW50X2lkIjo1LCJwYXJ0bmVyX2lkIjo2LCJwcm9wZXJ0aWVzX2lkIjozfQ==">
  </ins>

  <ins class="arenamainId"
  data-iframe_src="https://arenamain.id/game/bebas-hambatan"
  data-clientkey="eyJ0b3VjaHBvaW50X2lkIjo1LCJwYXJ0bmVyX2lkIjo2LCJwcm9wZXJ0aWVzX2lkIjozfQ==">
  </ins>
  
  <ins class="arenamainId"
  data-iframe_src="https://arenamain.id/game/mobil-gurun"
  data-clientkey="eyJ0b3VjaHBvaW50X2lkIjo1LCJwYXJ0bmVyX2lkIjo2LCJwcm9wZXJ0aWVzX2lkIjozfQ==">
  </ins>

  <ins class="arenamainId"
  data-iframe_src="https://arenamain.id/game/balap-liar"
  data-clientkey="eyJ0b3VjaHBvaW50X2lkIjo1LCJwYXJ0bmVyX2lkIjo2LCJwcm9wZXJ0aWVzX2lkIjozfQ==">
  </ins>

  <ins class="arenamainId"
  data-iframe_src="https://arenamain.id/game/balap-maut"
  data-clientkey="eyJ0b3VjaHBvaW50X2lkIjo1LCJwYXJ0bmVyX2lkIjo2LCJwcm9wZXJ0aWVzX2lkIjozfQ==">
  </ins>

  <ins class="arenamainId"
  data-iframe_src="https://arenamain.id/game/ayam-jagoan"
  data-clientkey="eyJ0b3VjaHBvaW50X2lkIjo1LCJwYXJ0bmVyX2lkIjo2LCJwcm9wZXJ0aWVzX2lkIjozfQ==">
  </ins>
  
  <ins class="arenamainId"
  data-iframe_src="https://arenamain.id/game/gali-yuk"
  data-clientkey="eyJ0b3VjaHBvaW50X2lkIjo1LCJwYXJ0bmVyX2lkIjo2LCJwcm9wZXJ0aWVzX2lkIjozfQ==">
  </ins>

  <ins class="arenamainId"
  data-iframe_src="https://arenamain.id/game/macet"
  data-clientkey="eyJ0b3VjaHBvaW50X2lkIjo1LCJwYXJ0bmVyX2lkIjo2LCJwcm9wZXJ0aWVzX2lkIjozfQ==">
  </ins>

  <ins class="arenamainId"
  data-iframe_src="https://arenamain.id/game/ular-tambah"
  data-clientkey="eyJ0b3VjaHBvaW50X2lkIjo1LCJwYXJ0bmVyX2lkIjo2LCJwcm9wZXJ0aWVzX2lkIjozfQ==">
  </ins>

    <Header v-if="!is_mobile"/>
    <HeaderMobile v-bind:window_width="window_width" v-bind:window_height="window_height" v-else/>

    <div id="content_view" class=" scroll_y" style="height: 100%;"> 
      <router-view v-bind:key="$route.fullPath"/> <!-- add $route.fullpath to reload when change route... -->
      <Footer />
    </div>

</div>
</template>

<script>
import * as platform from '@/components/platform.js'
import Header from '@/components/Header.vue'
import HeaderMobile from '@/components/HeaderMobile.vue'
import Footer from '@/components/Footer.vue'

export default {
    name: 'App',
    components: {
	Header,
	HeaderMobile,
	Footer,
    },
    data(){
	return{
	    is_mobile: false,
	    window_width: window.innerWidth,
	    window_height: window.innerHeight,
	}
    },
    methods:{
	on_resize(){
	    this.window_width = window.innerWidth;
	    this.window_height = window.innerHeight;

            if(this.window_width <= 1024){
              this.is_mobile=true;
            }
            else{
              this.is_mobile=false;
            }
	},
    },
    created(){

      // add script to body
      var arenamain_sdk_script = document.createElement("script"); 
      arenamain_sdk_script.setAttribute('src', 'https://cdn.jsdelivr.net/gh/kevingamotions/test_own_sdk@00a6247fa92079ce2d9a86c8b566d44c025fc0be/arenamain_integration_sdk.js');
      arenamain_sdk_script.setAttribute("crossorigin", "anonymous");
      document.body.appendChild(arenamain_sdk_script);

      window.addEventListener("resize", this.on_resize);
    },
    mounted(){
	this.is_mobile = platform.detect_mobile();
        console.log(window.innerWidth);
        this.on_resize();
    },
    updated(){
    },
    unmounted(){
	window.removeEventListener("resize", this.on_resize);
    },

}
</script>

<style>
#app {
  font-family:  Rubik, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
