import { createWebHistory, createRouter } from "vue-router";

import Home from "@/views/Home.vue";
import Arenamain from "@/views/Arenamain.vue";
import About from "@/views/About.vue";
import Games from "@/views/Games.vue";
import Career from "@/views/Career.vue";
import Contact from "@/views/Contact.vue";
import PrivacyPolicy from "@/views/PrivacyPolicy.vue";

import {publicPath} from '../../vue.config.js'

const routes = [
    {
	path: '/',
	name: 'Home',
	component: Home,
    },
    {
	path: '/arenamain',
	name: 'Arenamain',
	component: Arenamain,
    },
    {
	path: '/about',
	name: 'About',
	component: About,
    },
    {
	path: '/games',
	name: 'Games',
	component: Games,
    },
    {
	path: '/careers',
	name: 'Career',
	component: Career,
    },
    {
	path: '/contact',
	name: 'Contact',
	component: Contact,
    },
    {
	path: '/privacypolicy',
	name: 'PrivacyPolicy',
	component: PrivacyPolicy,
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL), routes,
    base: publicPath,
    mode: 'history',
});

export default router;
