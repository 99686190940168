<template>
<!-- 
     kevin haryo kuncoro
     kevinhyokun91@gmail.com
  -->

  <div style="width: 100%; height: 40px;"></div>

  <div style="margin-left: 40px; width: 100%; text-align: left;">


    <p style="margin-right: 100px">
      <strong>GAMOTIONS PRIVACY POLICY</strong>
    </p>
    <p style="margin-right: 100px">
      Your privacy is important to us, and we take our responsibility of caring
      for it seriously. We respect your privacy rights and are committed to
      protecting the personal information that is collected about you. This
      Privacy Policy describes what information Gamotions collects and how we
      use, disclose, transfer, and store it. If you do not want Gamotions to
      collect, store, use or share your information in the ways described in this
      Privacy Policy, you may not be able to play our games or use our services.
    </p>
    <p style="margin-right: 100px">
      All of our Services, whether used on mobile devices, PCs or other
      platforms, are governed by this Privacy Policy.
    </p>
    <p style="margin-right: 100px">
      By using the Services, you hereby consent to this Privacy Policy.
    </p>
    <p style="margin-right: 100px">
      This Privacy Policy is effective as of May 13, 2019.
    </p>
    <p style="margin-right: 100px">
      If you have any questions, comments or concerns regarding our Privacy
      Policy and/or practices, please see the “How to contact us” section below.
    </p>
    <p style="margin-right: 100px">
      <strong>1 – How we collect your information</strong>
    </p>
    <p style="margin-right: 100px">
      Gamotions collects some information from you when you: (a) create an
      account, (b) play our games or use game-related services, (c) make a
      purchase within our Services, (d) create user-generated content, (e) watch
      advertisements within our Services, (f) sign up for a newsletter, (g)
      contact Gamotions customer service and take part in technical-support
      sessions, (h) enter a contest or participate in a challenge held by
      Gamotions, (i) chat with other users of the Services, (j) or share or
      contribute to our social-media channels and other community websites.
    </p>
    <p style="margin-right: 100px">
      <strong>2 – Information we collect</strong>
    </p>
    <p style="margin-right: 100px">
      Gamotions collects only adequate and relevant information limited to the
      purposes defined hereunder.
    </p>
    <p style="margin-right: 100px">
      <strong>a – Information you provide us</strong>
    </p>
    <ul>
      <li>
	<p style="margin-right: 100px">
	  Contact information (such as your name and email address)
	</p>
      </li>
      <li>
	<p style="margin-right: 100px">
	  Your age
	</p>
      </li>
      <li>
	<p style="margin-right: 100px">
	  Your username and password
	</p>
      </li>
      <li>
	<p style="margin-right: 100px">
	  Profile information (such as a profile picture)
	</p>
      </li>
      <li>
	<p style="margin-right: 100px">
	  Information that you provide when seeking help from our customer
	  service (such as chat logs and customer-support tickets)
	</p>
      </li>
      <li>
	<p style="margin-right: 100px">
	  Other information you provide us (such as information used to
	  identify a lost account)
	</p>
      </li>
    </ul>
    <p style="margin-right: 100px">
      <strong>
	b – Information we collect automatically directly from you when you use
	our Services
      </strong>
    </p>
    <ul>
      <li>
	<p style="margin-right: 100px">
	  Information about your game progress and activity
	</p>
      </li>
      <li>
	<p style="margin-right: 100px">
	  Information about your interactions with other players
	</p>
      </li>
      <li>
	<p style="margin-right: 100px">
	  IP address and device identifiers (such as your device ID,
	  advertising ID, MAC address, IMEI)
	</p>
      </li>
      <li>
	<p style="margin-right: 100px">
	  Information about your device (such as device name and operating
	  system, browser information, including browser type and the
	  language you prefer)
	</p>
      </li>
      <li>
	<p style="margin-right: 100px">
	  Information we collect using cookies and similar technologies
	  (Note: You can refer to our Cookies Policy to learn more about
	  opting out of such technologies)
	</p>
      </li>
      <li>
	<p style="margin-right: 100px">
	  General geolocation information
	</p>
      </li>
      <li>
	<p style="margin-right: 100px">
	  Precise geolocation information (GPS)
	</p>
      </li>
      <li>
	<p style="margin-right: 100px">
	  Gamotions player ID
	</p>
      </li>
    </ul>
    <p style="margin-right: 100px">
      <strong>c – Information we obtain from our partners</strong>
    </p>
    <ul>
      <li>
	<p style="margin-right: 100px">
	  Information that we receive if you decide to connect to a
	  third-party social network (such as Facebook)
	</p>
      </li>
      <li>
	<p style="margin-right: 100px">
	  Information from billing partners (if you subscribe to some games
	  or make an in-game purchase)
	</p>
      </li>
      <li>
	<p style="margin-right: 100px">
	  Information for advertising and analytics purposes, so we can
	  provide you with better Services
	</p>
      </li>
    </ul>
    <p style="margin-right: 100px">
      <strong>3 – How we use your information</strong>
    </p>
    <p style="margin-right: 100px">
      We primarily use the information to provide you with a better gaming
      experience, but we also use your information, both individually and
      combined, as follows:
    </p>
    <p style="margin-right: 100px">
      <strong>
	To operate our business and to enhance your gaming experience,
	including to:
      </strong>
    </p>
    <ul>
      <li>
	<p style="margin-right: 100px">
	  provide you with the Services that are suitable to your age and
	  compliant with the applicable laws of your country of residence
	</p>
      </li>
      <li>
	<p style="margin-right: 100px">
	  provide gaming experiences that you may like and functionalities
	  you have subscribed to
	</p>
      </li>
      <li>
	<p style="margin-right: 100px">
	  track your use of the Services to help us learn more about your
	  gaming activities and understand your preferences and tendencies,
	  so that we can personalize your experience, provide you with
	  in-game offers and notifications that are tailored to you, and
	  otherwise enhance your gaming experience
	</p>
      </li>
      <li>
	<p style="margin-right: 100px">
	  facilitate sharing on social networks
	</p>
      </li>
      <li>
	<p style="margin-right: 100px">
	  register your participation in a contest and contact you in case of
	  prizes
	</p>
      </li>
      <li>
	<p style="margin-right: 100px">
	  offer you the opportunity to take part in playtesting (game tests)
	</p>
      </li>
      <li>
	<p style="margin-right: 100px">
	  protect against cheating, crime or fraud, or for other security
	  reasons
	</p>
      </li>
      <li>
	<p style="margin-right: 100px">
	  identify and fix bugs and service errors
	</p>
      </li>
      <li>
	<p style="margin-right: 100px">
	  use it for administration, analytics, research, and optimization
	</p>
      </li>
      <li>
	<p style="margin-right: 100px">
	  compile statistics
	</p>
      </li>
      <li>
	<p style="margin-right: 100px">
	  comply with all regulatory and legal requirements, and answer any
	  requests from Data Protection Authorities or any law-enforcement
	  agency
	</p>
      </li>
    </ul>
    <p style="margin-right: 100px">
      <strong>
	To provide you with support, communicate with you and respond to any
	requests or questions you have submitted through our customer support.
      </strong>
    </p>
    <p style="margin-right: 100px">
      <strong>To personalize our communication with you, including to:</strong>
    </p>
    <ul>
      <li>
	<p style="margin-right: 100px">
	  handle your subscription to, and opting-out of, our newsletters
	</p>
      </li>
      <li>
	<p style="margin-right: 100px">
	  contact you to promote our Services and contests we may offer
	</p>
      </li>
      <li>
	<p style="margin-right: 100px">
	  send you, with your prior consent, promotions or marketing
	  materials (we will provide you with the opportunity to opt out of
	  receiving such materials in the future)
	</p>
      </li>
      <li>
	<p style="margin-right: 100px">
	  provide you with advertising that better suits your interests and
	  profile and is age-/gender-appropriate and targeted to your general
	  location
	</p>
      </li>
      <li>
	<p style="margin-right: 100px">
	  serve and measure the effectiveness of advertising
	</p>
      </li>
      <li>
	<p style="margin-right: 100px">
	  collect any personal information you may disclose through messages
	  you choose to send to another user or defined group of users
	  through our message, chat, post or other in-game function, as well
	  as to collect necessary personal information of the user(s) you are
	  contacting, in order to facilitate the communication (Note: We will
	  never collect any information sent outside of the functionality of
	  our Services, including through emails or text messages)
	</p>
      </li>
      <li>
	<p style="margin-right: 100px">
	  personalize your content and offers
	</p>
      </li>
      <li>
	<p style="margin-right: 100px">
	  send you push notifications (if you choose to allow that
	  function)—only for users 13 years old or older in the United States
	  (16 or older in Europe)
	</p>
      </li>
    </ul>
    <p style="margin-right: 100px">
      For some purposes described above, Gamotions may use and perform analytics.
      Analytics information supports business analysis and operations, allows
      product development, improves products and Services, personalizes content,
      provides advertising, and makes recommendations.
    </p>
    <p style="margin-right: 100px">
      We will normally collect personal information from you only:
    </p>
    <ul>
      <li>
	<p style="margin-right: 100px">
	  when we have your consent to do so
	</p>
      </li>
      <li>
	<p style="margin-right: 100px">
	  when we need the personal information to fulfil a contract with you
	  and provide you with the Services, such as customer services, bug
	  and service-error detection, and fraud prevention
	</p>
      </li>
      <li>
	<p style="margin-right: 100px">
	  when the processing is in our legitimate interests (when we serve
	  and measure the effectiveness of advertising or marketing
	  campaigns, analyse your gaming activities, protect and improve our
	  products and services) and not overridden by your rights, or
	</p>
      </li>
      <li>
	<p style="margin-right: 100px">
	  when we have a legal obligation to collect personal information
	  from you
	</p>
      </li>
    </ul>
    <p style="margin-right: 100px">
      <strong>4 – How we retain your information</strong>
    </p>
    <p style="margin-right: 100px">
      Gamotions retains, uses and stores your information as long as you are
      playing our games or using our Services, and also for the period necessary
      to fulfil all the purposes described in this Privacy Policy, in compliance
      with the applicable laws of your country of residence. We want to inform
      you that a longer retention period could be required to resolve disputes,
      or answer regulatory or police requests. In such cases, the collected
      information will be used and stored until the request is closed.
    </p>
    <p style="margin-right: 100px">
      <strong>5 – How we share your information</strong>
    </p>
    <p style="margin-right: 100px">
      <u>With third parties</u>
    </p>
    <p style="margin-right: 100px">
      We use third-party services that have limited access to your information
      for specific purposes, such as administration, analytics, optimization,
      advertising, prize delivery, marketing assistance, customer service, and
      data analysis. They assist us with our operations.
      <br/>
      Such third parties are authorized to use your information for these
      purposes only.
    </p>
    <p style="margin-right: 100px">
      If you decide, while using our Services, to use social sharing features
      such as “Share” or “Like” on Facebook or other third-party social network
      sites, this may allow the sharing and collection of personal and
      non-personal information both to and from such third-party social networks.
      <br/>
      You should visit the policies of such third parties for more information
      about their information-collection practices.
    </p>
    <p style="margin-right: 100px">
      <u>With authorities</u>
    </p>
    <p style="margin-right: 100px">
      We reserve the right to report to law enforcement agencies activities that
      we, in good faith, believe to be illegal. We will investigate or take
      action to counter illegal activity, suspected abuse or unauthorized use of
      the Services, or to protect the property or safety of others. We also may
      disclose your information if required by any law or court order worldwide,
      if the information relates to actual or threatened harmful conduct.
      <br/>
      In that case, we will consider applicable local laws, the nature of the
      order, as well as the legitimacy and proportionality of your information.
    </p>
    <p style="margin-right: 100px">
      <u>In case of sale of our company</u>
    </p>
    <p style="margin-right: 100px">
      In connection with any sale of our company or any line of business
      (including the assets relating thereto), customer information generally is
      one of the transferred business assets. In the event of any such sale or
      disposition of any or all of our business, your information may be
      transferred to the acquirer.
    </p>
    <p style="margin-right: 100px">
      <strong>6 – International operations</strong>
    </p>
    <p style="margin-right: 100px">
      Gamotions is headquartered in Indonesia. We have taken measures to ensure
      that your personal information is treated securely and lawfully, and that
      it receives an adequate level of protection in the jurisdictions in which
      we process it. If you are located in the European Economic Area (“EEA”) or
      Switzerland, we provide adequate protection for the transfer of personal
      information to countries outside of the EEA or Switzerland based on the
      standard contractual clauses authorized by the European Commission or other
      appropriate safeguards defined in Article 46, EU General Data Protection
      Regulation 2016/67 (GDPR).
    </p>
    <p style="margin-right: 100px">
      <strong>
	7 – Security and confidentiality of your personal information
      </strong>
    </p>
    <p style="margin-right: 100px">
      We have implemented security measures to safeguard and help prevent
      unauthorized access, maintain data security, and correctly use the personal
      information collected through the Services.
      <br/>
      We take, and force applicable third parties to take, reasonable precautions
      to protect your personal information against loss, misuse, unauthorized
      disclosure, alteration, and destruction. However, please remember that no
      transmission of data over the Internet or any wireless network can be
      guaranteed to be 100% secure. As a result, while we strive to protect your
      information, we cannot guarantee the security of your information and do
      not assume any responsibility for the unauthorized use of, or access to,
      your information that is under our control.
      <br/>
      When we use third parties to assist us in processing your personal
      information or credit card information, we take reasonable steps to require
      that they comply with this Privacy Policy and otherwise protect your
      personal information.
    </p>
    <p style="margin-right: 100px">
      If there is a breach of security involving your personal information,
      including credit card information, from our Services, we will inform you
      immediately according to your local regulations relating to the
      notification of information Breaches, and take commercially reasonable
      steps to rectify such breach.
    </p>
    <p style="margin-right: 100px">
      If you believe your personal information is being improperly used by us or
      any third party, please immediately notify us via email at    <a href="mailto:contact@Gamotions.com">contact@Gamotions.com</a>.
    </p>
    <p style="margin-right: 100px">
      <strong>8 – Children</strong>
    </p>
    <p style="margin-right: 100px">
      We encourage parents to instruct their children about privacy and the way
      they use their information on the Internet. Children should never disclose
      their name, address or phone number, or any personal information, without
      their parents’ prior permission.
    </p>
    <p style="margin-right: 100px">
      We consider a user to be a child if they are under the age of 13, unless
      more stringent regulation applies in their country of residence. In EU
      countries, users under the age of 16 are considered children, unless the
      data protection regulation of their country of residence specifies another
      age.
    </p>
    <p style="margin-right: 100px">
      If we consider that any of our Services are directed at users under the
      minimum legal age required in their country of residence, we can block such
      users from using the Services or from providing information and/or limit
      the collection of information from such users as more particularly
      described below.
    </p>
    <p style="margin-right: 100px">
      No personal information should be submitted to Gamotions by users under the
      minimum legal age required in their country of residence.
    </p>
    <p style="margin-right: 100px">
      We apply the following limitations to personal information collection for a
      user that is not of the minimum legal age required in their country of
      residence.
    </p>
    <p style="margin-right: 100px">
      Such a user cannot:
    </p>
    <ul>
      <li>
	<p style="margin-right: 100px">
	  display his/her real name or any information in the games
	</p>
      </li>
      <li>
	<p style="margin-right: 100px">
	  access the chat, leaderboard, forums, SNS (Facebook, etc.)
	</p>
      </li>
      <li>
	<p style="margin-right: 100px">
	  subscribe to newsletters
	</p>
      </li>
      <li>
	<p style="margin-right: 100px">
	  use any other feature where users could appear under their real
	  names
	</p>
      </li>
    </ul>
    <p style="margin-right: 100px">
      With respect to such a user, we will prevent the collection and use of
      his/her precise geolocation data and display to him/her contextual
      advertising only (excluding any behavioral advertising).
    </p>
    <p style="margin-right: 100px">
      Children can be invited to take part in testing our games (playtests) with
      their parents' authorization and/or their parents' physical presence being
      required depending on the country where the playtest is conducted.
    </p>
    <p style="margin-right: 100px">
      Parents must exercise their privacy rights for their children. Please refer
      to the "Your rights" section of this Privacy Policy.
    </p>
    <p style="margin-right: 100px">
      <strong>10 – Your rights</strong>
    </p>
    <p style="margin-right: 100px">
      We ask that you keep the information that you provide to us updated. You
      can correct any information you have provided to us by contacting us.
    </p>
    <p style="margin-right: 100px">
      You represent and warrant that all information you provide to us is true
      and correct, and relates to you and not to any other person.
    </p>
    <p style="margin-right: 100px">
      You can exercise your rights at any time and ask Gamotions for access to,
      or rectification or erasure of, your information. According to your local
      regulations, if you are a European user, you can also request limitations
      to the processing of your personal information, object to the processing of
      your information, or exercise your right to data portability.
    </p>
    <p style="margin-right: 100px">
      Please note that other third parties (our data partners) may be controllers
      of your information. You can obtain more details and opt out of the sharing
      of your personal information to third parties for any purpose by contacting
      us
    </p>
    <p style="margin-right: 100px">
      If you have previously consented to any direct marketing communication from
      us or subscribed to our newsletters, you can unsubscribe by clicking on a
      link available in each communication and newsletter we send you.
    </p>
    <p style="margin-right: 100px">
      If you want to decline to permit cookies or other tracking technologies,
      please read our Cookies Policy on our websites.
    </p>
    <p style="margin-right: 100px">
      Please note that game functionality, Services and features can be affected
      by your actions. Some of your information and its processing is mandatory
      for Gamotions to provide the Services.
    </p>
    <p style="margin-right: 100px">
      Your actions can oblige Gamotions to stop providing the Services. When the
      conditions below apply, your game will be reset:
    </p>
    <ul>
      <li>
	<p style="margin-right: 100px">
	  If you ask for the modification of this mandatory information, we
	  may not be able to continue to link the game or game Services to
	  you.
	</p>
      </li>
      <li>
	<p style="margin-right: 100px">
	  If you request limitations to processing that are mandatory for
	  Gamotions, or you object to such processing (in which case, we may
	  not be able to provide you with the game or game Services).
	</p>
      </li>
      <li>
	<p style="margin-right: 100px">
	  If you withdraw your consent or ask for the deletion of your
	  information.
	</p>
      </li>
    </ul>
    <p style="margin-right: 100px">
      If you withdraw your consent or ask for the deletion of your information,
      we will not use or process your information anymore but we will store it to
      answer regulatory or police requests. We also reserve the right to retain
      an archive of such information for a commercially reasonable time to ensure
      that its deletion does not affect the integrity of our data; and we further
      reserve the right to retain an anonymous version of such information.
    </p>
    <p style="margin-right: 100px">
      If you are a parent of a child who is under 13 years of age (or under
      another age specified by applicable regulations) and believe your child has
      provided us with information, please inform us and we will delete the
      information from our system.
    </p>
    <p style="margin-right: 100px">
      To preserve the confidentiality of your information, we must be sure to
      correctly identify you. For this reason, please provide us with a copy of
      an official document that can prove your identity. This copy will be safely
      destroyed within two months of your request being received and closed.
    </p>
    <p style="margin-right: 100px">
      Our team will look into your request and will answer you within one month,
      unless your request requires further investigation. You’ll be specifically
      informed if that is the case.
    </p>
    <p style="margin-right: 100px">
      We remind European users that they have a right to lodge a complaint with a
      European Data Protection Supervisor.
    </p>
    <p style="margin-right: 100px">
      <strong>11 – Third-party services</strong>
    </p>
    <p style="margin-right: 100px">
      The Services may link to external sites that are beyond our control. This
      Privacy Policy only covers the way we treat personal information and
      non-personal information we collect on the Services. By accessing any
      external sites, you agree to the privacy policies of those sites. Please
      understand that external sites may have different policies with respect to
      the collection, use and disclosure of your personal information. We have no
      control over, and are not responsible for, the privacy practices of such
      third parties.
    </p>
    <p style="margin-right: 100px">
      Therefore, you are encouraged to review the privacy policies of all third
      parties' services. Without limiting anything in the following section, we
      are not liable in any way for the actions, inactions or policies of any
      external services.
    </p>
    <p style="margin-right: 100px">
      <strong>12 – Changes to our Privacy Policy</strong>
    </p>
    <p style="margin-right: 100px">
      We reserve the right to change this Privacy Policy at any time. In the
      event that we make material changes to this Privacy Policy, they will be
      re-posted in the "Privacy" section of our websites and apps with the date
      the modifications were made indicated at the top of the page. Therefore,
      please review this Privacy Policy from time to time so that you are aware
      when any changes are made to it. Your continued use of the Services after
      such change(s) constitutes your acceptance of any such change(s), and if
      you do not accept any changes, you may choose not to use the Services or
      opt out by sending us appropriate notice. If you would like further
      information about any recent changes made, please send your request to    <a href="mailto:contact@Gamotions.com">contact@Gamotions.com</a><u>.</u>
    </p>
    <p style="margin-right: 100px">
      <strong>13 – Legal disclaimer</strong>
    </p>
    <p style="margin-right: 100px">
      We do not represent or warrant that our Services will be available at all
      times, or that your use of the Services will be uninterrupted or
      error-free. We are not responsible for your ability to access the Services
      or for matters beyond our control. This Privacy Policy is governed by the
      laws of your country of residence. Any dispute arising from the use of the
      Services shall be submitted to the competent courts of your country of
      residence.
    </p>
    <p style="margin-right: 100px">
      <strong>14 – How to contact us</strong>
    </p>
    <p style="margin-right: 100px">
      If you have any questions, comments or concerns regarding our Privacy
      Policy and/or practices, please contact us:
    </p>
    <p style="margin-right: 100px">
      by sending an email to    <a href="mailto:contact@Gamotions.com">contact@Gamotions.com</a>
      <br/>
      <br/>
      <br/>
    </p>
    


  </div>
</template>

<script>
export default{
    name: 'PrivacyPolicy',
    data(){
	return{
	    content_view: document.getElementById('content_view'),
	}
    },
    mounted(){
	this.content_view.scrollTop = 0; // scroll to top of page
    }
}
</script>

<style>
</style>
