<template>
<!-- 
     kevin haryo kuncoro
     kevinhyokun91@gmail.com
     kevin.haryo@timobile.tech
  -->

<div class="pattern_bg_white_x" style="width: 100%; height: auto; background-color: #abbcd6;">
  <div v-if="!is_mobile" style="width: 100%; height: 100%">

    <div style="width: 100%; height: 100%; display: flex">
      <div style="width: 50%; height: 100%; background-color: none">

        <div class="display_center_x" style="font-family: bs_condensed; text-align: left; color: white; width: 100%; height: auto; font-size: 6vw;  background-color: none; user-select: none; margin-left: 14.5%;  margin-top: 10%">
  	 COME PLAY WITH US 
        </div>
        <div class="display_center_x" style="font-family: bs_condensed; text-align: left; color: white; width: 80%; height: auto; font-size: 4vw;  background-color: none; user-select: none; margin-left: 15%;  margin-top: 1%">
  	 Why You Might Like It Here:
        </div>
        <div class="display_center_x" style="font-family: bs_condensed; text-align: left; color: white; width: 80%; height: auto; font-size: 2vw;  background-color: none; user-select: none; margin-left: 15%;  margin-top: 1%">
  	 If you love to think, talk, play and make games, then Gamotions is the right place for you. 
        </div>
        <div class="display_center_x" style="font-family: bs_condensed; text-align: left; color: white; width: 80%; height: auto; font-size: 2vw;  background-color: none; user-select: none; margin-left: 15%;  margin-top: 3%">
          We are a fast-growing company of proactive and independent teams with the freedom to do what they think is best for their players, for their games and for the company at large. 
        </div>
        <div class="display_center_x" style="font-family: bs_condensed; text-align: left; color: white; width: 80%; height: auto; font-size: 2vw;  background-color: none; user-select: none; margin-left: 15%;  margin-top: 3%">
          We believe in the power of Indonesian gaming communities, from the makers to the players.
        </div>

      </div>
      <div style="width: 50%; height: 100%; display: flex; background-color: none">
          <img v-bind:src="career2_img" style="width: 90%; height: auto; margin-top: 13%; margin-bottom: 15%" />
      </div>
    </div>

  </div>
  <div v-else>

    <div style="width: 100%; height: 100%; display: flex; flex-direction: column;">
      <div style="width: 100%; height: 100%; background-color: none">

        <div class="display_center_x" style="font-family: bs_condensed; text-align: left; color: white; width: 100%; height: auto; font-size: 11vw;  background-color: none; user-select: none; margin-left: 10.5%;  margin-top: 10%">
  	 COME PLAY WITH US 
        </div>
        <div class="display_center_x" style="font-family: bs_condensed; text-align: left; color: white; width: 80%; height: auto; font-size: 8vw;  background-color: none; user-select: none; margin-left: 11%;  margin-top: 1%">
  	 Why You Might Like It Here:
        </div>
        <div class="display_center_x" style="font-family: bs_condensed; text-align: left; color: white; width: 80%; height: auto; font-size: 5.4vw;  background-color: none; user-select: none; margin-left: 11%;  margin-top: 1%">
  	 If you love to think, talk, play and make games, then Gamotions is the right place for you. 
        </div>
        <div class="display_center_x" style="font-family: bs_condensed; text-align: left; color: white; width: 80%; height: auto; font-size: 5.4vw;  background-color: none; user-select: none; margin-left: 11%;  margin-top: 3%">
          We are a fast-growing company of proactive and independent teams with the freedom to do what they think is best for their players, for their games and for the company at large. 
        </div>
        <div class="display_center_x" style="font-family: bs_condensed; text-align: left; color: white; width: 80%; height: auto; font-size: 5.4vw;  background-color: none; user-select: none; margin-left: 11%;  margin-top: 3%">
          We believe in the power of Indonesian gaming communities, from the makers to the players.
        </div>

      </div>
      <div class="display_center_x" style="width: 100%; height: 100%; background-color: none">
          <img v-bind:src="career2_img" style="width: 90%; height: auto; margin-top: 13%; margin-bottom: 15%" />
      </div>
    </div>

  </div>
</div>

<!-- section 3 -->
<div class="pattern_bg" style="width: 100%; background-color: #F3F0F0;">

  <div v-if="!is_mobile">

    <div style="user-select: none; font-weight: bold; font-size: 3.5vw; color: rgba(0, 0, 0, 0.0)"> ENTER AJA, Just new line ha hi hu</div>
  
    <div style="user-select:none; font-family: bs_condensed; font-weight: bold; font-size: 2vw;">
      WE ARE LOOKING NEW POSITION TO EXPAND GAMOTIONS FAMILY
    </div>
    <div style="user-select: none; font-family: bs_condensed; font-size: 1.7vw; margin-top: 1%;">
      Talented Passionate and Highly Motivated
    </div>
  
    <div style="user-select: none; font-weight: bold; font-size: 3vw; color: rgba(0, 0, 0, 0.0)"> ENTER AJA, Just new line ha hi hu</div>
  
    <div class="display_center_x" style="width: 80%; heiight: 100%; background-color: none;">
      <div style="display: flex;">
	<div style="padding: 10px; width: 50%">
	  <img v-bind:src="job_1" class="pres" />
	</div>
	<div style="padding: 10px; width: 50%">
	  <img v-bind:src="job_2" class="pres" />
	</div>
	<div style="padding: 10px; width: 50%">
	  <img v-bind:src="job_3" class="pres" />
	</div>
      </div>
    </div>
    <div class="display_center_x" style="width: 80%; height: auto; background-color: none;">
      <div class="display_center_x" style="width: 80%; height: auto; background-color:none;">
        <div style="display: flex;">
	  <div class="display_center_x" style="padding: 10px; width: 41%">
	    <img v-bind:src="job_4" class="pres" />
	  </div>
	  <div class="display_center_x" style="padding: 10px; width: 41%">
	    <img v-bind:src="job_5" class="pres" />
	  </div>
        </div>
      </div>
    </div>

  
    <div style="user-select: none; font-weight: bold; font-size: 3.5vw; color: rgba(0, 0, 0, 0.0)"> ENTER AJA, Just new line ha hi hu</div>
  </div>
  <div v-else> <!-- mobile -->

    <div style="user-select: none; font-weight: bold; font-size: 7.5vw; color: rgba(0, 0, 0, 0.0)"> ENTER AJA, Just new line ha hi hu</div>

    <div class="display_center" style="width: 90%; user-select:none; font-family: bs_condensed; font-weight: bold; font-size: 7vw;">
      WE ARE LOOKING NEW POSITION TO EXPAND GAMOTIONS FAMILY
    </div>
    <div class="display_center" style="width: 90%; user-select: none; font-family: bs_condensed; font-size: 6vw; margin-top: 1%;">
      Talented Passionate and Highly Motivated
    </div>
  

    <div class="display_center_x" style="width: 70%; background-color: none">

      <div style="display: flex; flex-direction: column;">
	<div>
	  <img v-bind:src="job_1" class="pres" />
	</div>
	<div>
	  <img v-bind:src="job_2" class="pres" />
	</div>
	<div>
	  <img v-bind:src="job_3" class="pres" />
	</div>
	<div>
	  <img v-bind:src="job_4" class="pres" />
	</div>
	<div>
	  <img v-bind:src="job_5" class="pres" />
	</div>
      </div>

    </div>

  </div>
  
</div>

<!-- section 4 -->

<div class="pattern_bg_white_s" style="width: 100%; background-color: #FF9D0B">
  
  <div v-if="!is_mobile">
    
    <div style="user-select: none; font-weight: bold; font-size: 3.0vw; color: rgba(0, 0, 0, 0.0)"> ENTER AJA, Just new line ha hi hu</div>
    
    <div style="font-family: bs_condensed; font-size: 1.6vw; color: #45466b">
      Send Your Resume & Greatest Portofolio Now to 
    </div>

    <div style="user-select: none; font-weight: bold; font-size: 0.7vw; color: rgba(0, 0, 0, 0.0)"> ENTER AJA, Just new line ha hi hu</div>

    <div style="font-family: bs_condensed;">
      <span style="font-size: 2.3vw; color: #45466b">
        contact@gamotions.com or

	<router-link to="/contact" style="text-decoration: none;">
	  <span style="color: #172fa4; font-size: 2.3vw;">
	    Contact Us
	  </span>
	</router-link>

      </span>
    </div>

    <div style="user-select: none; font-weight: bold; font-size: 3.0vw; color: rgba(0, 0, 0, 0.0)"> ENTER AJA, Just new line ha hi hu</div>
    
    <div style="font-family: bs_condensed; font-size: 1.6vw; color: #45466b">
      We’ll get back to you shortly
    </div>
    
    <div style="user-select: none; font-weight: bold; font-size: 0.7vw; color: rgba(0, 0, 0, 0.0)"> ENTER AJA, Just new line ha hi hu</div>

  </div>
  <div v-else>

    <div style="user-select: none; font-weight: bold; font-size: 1.4vw; color: rgba(0, 0, 0, 0.0)"> ENTER AJA, Just new line ha hi hu</div>
    
    <div style="font-family: bs_condensed; font-size: 4.6vw; color: #45466b">
      Send Your Resume & Greatest Portofolio Now to 
    </div>

    <div style="user-select: none; font-weight: bold; font-size: 0.9vw; color: rgba(0, 0, 0, 0.0)"> ENTER AJA, Just new line ha hi hu</div>

    <div style="font-family: bs_condensed;">
      <span style="font-size: 6.3vw; color: #45466b">
        contact@gamotions.com or

	<router-link to="/contact" style="text-decoration: none;">
	  <span style="color: #172fa4; font-size: 6.3vw;">
	    Contact Us
	  </span>
	</router-link>

      </span>
    </div>
    
    <div style="user-select: none; font-weight: bold; font-size: 1.0vw; color: rgba(0, 0, 0, 0.0)"> ENTER AJA, Just new line ha hi hu</div>
    
    <div style="font-family: bs_condensed; font-size: 4.6vw; color: #45466b">
      We’ll get back to you shortly
    </div>
    
    <div style="user-select: none; font-weight: bold; font-size: 1.4vw; color: rgba(0, 0, 0, 0.0)"> ENTER AJA, Just new line ha hi hu</div>
  </div>

</div>

</template>

<script>
import * as platform from '@/components/platform.js'
export default{
    name: 'Career',
    data(){
	return{
	    content_view: document.getElementById('content_view'),
	    is_mobile: false,
	    job_1: require('@/assets/images/careers/1.png'),
	    job_2: require('@/assets/images/careers/2.png'),
	    job_3: require('@/assets/images/careers/3.png'),
	    job_4: require('@/assets/images/careers/4.png'),
	    job_5: require('@/assets/images/careers/5.png'),
	    career2_img: require('@/assets/images/careers/career2.png'),
	}
    },
    mounted(){
	this.content_view.scrollTop = 0; // scroll to top of page
	this.is_mobile = platform.detect_mobile();
        this.on_resize();
    },
    created() {
      window.addEventListener("resize", this.on_resize);
    },
    unmounted() {
      window.removeEventListener("resize", this.on_resize);
    },
    methods: {
    on_resize() {
      if(window.innerWidth <= 1024){
        this.is_mobile=true;
      }
      else{
        this.is_mobile=false;
      }
    }
  }

}
</script>

<style>
</style>
