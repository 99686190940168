<template style="height: 100%;">

<!-- 
     kevin haryo kuncoro
     kevinhyokun91@gmail.com
     kevin.haryo@timobile.tech
  -->

  <div style="position: relative; background-color: #474a6b" v-if="!is_mobile">

    <div style="width: 80%; height: 100%; position: absolute; background-color: none; top: 12%;">
      <div class="display_center_x" style="width: 80%; height: 100%; background-color: none; text-align: left; font-family: bs_condensed;">

	<div style="width: 100%; height: 2%"></div> <div class="text_white" style="font-size: 2.2vw;">
	  We are on a mission to bring frictionless 
	</div>
	<div class="text_white" style="font-size: 2.2vw;">
	  & instant gaming experiences to South-East Asian players and become the leading HTML5 gaming company across ASEAN.
	</div>

	<div style="width: 100%; height: 5%"></div>

	<div class="text_white" style="font-size: 1.8vw;">
	  We are involved at various levels of the gaming industry. 
	</div>

	<div style="width: 100%; height: 2%"></div>

	<div  class="text_white" style="font-size: 1.8vw;">
	  We operate as:
	  <ul class="menu_list_bullet" style="margin: 0; padding-left: 3%;">
	    <li class="text_white">a Game Studio</li>
	    <li class="text_white">a Game Publisher</li>
	    <li class="text_white">a Game Centre Solution Provider</li>
	  </ul>
	</div>

      </div>

    </div>

    <img v-bind:src="pres1" class="pres" />

  </div>
  <div v-else> <!-- section 1 mobile -->
    <div style="display: flex; flex-orientation: column; flex-wrap: wrap;">

      <div style="width: 100%; height: 355px; background-color: #474a6b; font-family: bs_condensed">

	<div class="display_center_x" style="width: 80%; height: 100%; text-align: left;">


	  <div style="width: 100%; height: 10%"></div>

	  <div class="text_white" style="font-size: 24px; font-size: 4.9vw;">
	    We are on a mission to bring frictionless & instant gaming
	  </div>
	  <div class="text_white" style="font-size: 24px; font-size: 4.9vw;">
	    experiences to South-East Asian players and become the leading HTML5 gaming company across ASEAN.
	  </div>
	  
	  <div style="width: 100%; height: 5%"></div>
	  
	  <div class="text_white" style="font-size: 24px; font-size: 4.3vw;">
	    We are involved at various levels of the gaming industry. 
	  </div>

	  <div style="width: 100%; height: 2%"></div>

	  <div  class="text_white" style="font-size: 24px; font-size: 4.3vw;">
	    We operate as:
	    <ul class="menu_list_bullet" style="margin: 0; padding-left: 5%;">
	      <li class="text_white">a Game Studio</li>
	      <li class="text_white">a Game Publisher</li>
	      <li class="text_white">a Game Centre Solution Provider</li>
	    </ul>
	  </div>

	</div>

      </div>

      <img v-bind:src="pres1" class="pres" />
    </div>
  </div>

  <!-- section 2 desktop -->
  <div style="position: relative; background-color: white" v-if="!is_mobile">

    <div style="width: 60%; height: 100%; position: absolute; background-color: none; font-family: bs_condensed">

      <div class="display_center_x" style="width: 80%; height: 100%; background-color: none; text-align: left;">
          <div style="width: 100%; height: 9%"></div>

	  <div class="text text_section2_desktop" style="font-weight: bold">
	    We focus on HTML5 gaming.
	  </div>
	  <div class="text text_section2_desktop">
	    HTML5 is the language of the web and provide instant gaming experience without the friction of an app installation. Our HTML5 game center, Arena Main and HTML5 games can be easily embedded into your site or app. 
	  </div>


	  <div style="width: 100%; height: 5%"></div>

	  <div class="text text_section2_desktop" style="font-weight: bold">
	    We are a B2B2C Gaming Solution provider. 
	  </div>
	  <div class="text text_section2_desktop">
	    We bring to businesses like yours the tools and experience required to provide games to their users, and take advantage of the massive related benefits.
	  </div>

	  <div style="width: 100%; height: 5%"></div>

	  <div class="text text_section2_desktop">
	    The Team is composed of digital, mobile & gaming business professionals with 15 years of experience.
	  </div>
      </div>

    </div>

    <img v-bind:src="pres2" class="pres" />

  </div>
  <!-- section mobile -->
  <div style="display: flex; flex-orientation: column; flex-wrap: wrap;"  v-else>

      <div style="width: 100%; height: 568px; background-color: white; font-family: bs_condensed">
	<div class="display_center_x" style="width: 80%; height: 100%; background-color:none; text-align: left;">


	  <div style="width: 100%; height: 9%"></div>

	  <div class="text text_section2_mobile" style="font-weight: bold">
	    We focus on HTML5 gaming.
	  </div>
	  <div style="user-select: none; font-weight: bold; font-size: 0.7vw; color: rgba(0, 0, 0, 0.0)"> ENTER AJA, Just new line ha hi hu</div>

	  <div class="text text_section2_mobile">
	    HTML5 is the language of the web and provide instant gaming experience without the friction of an app installation. Our HTML5 game center, Arena Main and HTML5 games can be easily embedded into your site or app. 
	  </div>

	  <div style="width: 100%; height: 5%"></div>

	  <div class="text text_section2_mobile" style="font-weight: bold">
	    We are a B2B2C Gaming Solution provider. 
	  </div>
	  <div class="text text_section2_mobile">
	    We bring to businesses like yours the tools and experience required to provide games to their users, and take advantage of the massive related benefits.
	  </div>

	  <div style="width: 100%; height: 5%"></div>

	  <div class="text text_section2_mobile">
	    The Team is composed of digital, mobile & gaming business professionals with 15 years of experience.
	  </div>

	  <div style="width: 100%; height: 5%"></div>

	</div>
      </div>

    <img v-bind:src="pres2_m_b" class="pres" />
  </div>

</template>

<script>
import * as platform from '@/components/platform.js'

export default{
    name: 'about',
    data(){
	return{
	    content_view: document.getElementById('content_view'),
	    is_mobile: false,
	    pres1: require('@/assets/images/about/img_1_.png'),
	    pres2: require('@/assets/images/about/img_2.png'),
	    pres2_m_b: require('@/assets/images/about/img_2_b_.png'),
	}
    },
    mounted(){
	this.content_view.scrollTop = 0; // scroll to top of page
	this.is_mobile = platform.detect_mobile();
        this.on_resize();
    },
    created() {
      window.addEventListener("resize", this.on_resize);
    },
    unmounted() {
      window.removeEventListener("resize", this.on_resize);
    },
    methods: {
    on_resize() {
      if(window.innerWidth <= 512){
        this.is_mobile=true;
      }
      else{
        this.is_mobile=false;
      }
    }
  }

}
</script>
<style>
  .text{
    user-select: none;
    color: #110d3a;
  }

  .text_white{
    user-select: none;
    color: #cececf;
  }

  .text_section2_desktop{
      font-size: 1.7vw;
  }
  .text_section2_mobile{
      font-size: 4.3vw;
  }
</style>
