<template>
<!-- 
     kevin haryo kuncoro
     kevinhyokun91@gmail.com
     kevin.haryo@timobile.tech
  -->

<div v-bind:style="{width: '100%', backgroundColor: 'none',backgroundImage: 'url(' + pattern1 + ')', backgroundSize: '532px 461px' }">
<!-- <div> -->
  
  <div style="width: 100%">

    <div style="width: 100%" v-if="!is_mobile">
      <img class="pres" v-bind:src="header" style="" />
    </div>
    <div style="width: 100%" v-else>
      <img class="pres" v-bind:src="header_m" style="" />
    </div>

    <!--
    <div class="display_center_x" style="width: 50%" v-if="!is_mobile">
      <a v-bind:href="arenamain_url" target="_blank">
	 <img class="pres" v-bind:src="arenamain_logo" style="margin-top: 100px; margin-bottom: 100px;" />
      </a>
    </div>
    <div class="display_center_x" style="width: 80%" v-else>
      <a v-bind:href="arenamain_url" target="_blank">
	<img class="pres" v-bind:src="arenamain_logo" style="margin-top: 50px; margin-bottom: 50px;" />
      </a>
    </div>
    -->

  </div>


  <div v-if="!is_mobile">

    <div style="display: flex;">
      <div class="game_item" style="width: 50%;" v-for="item in item_row_1" v-bind:key="item">
	<GameItem
	  v-bind:img_file="item.image_file"
	  v-bind:title="item.title"
          v-bind:url="item.url"
          v-bind:ins_index="item.ins_index"
          v-bind:is_show_button="1"
	  v-bind:title_font_size="1.2"
	  />
      </div>
    </div>


    <div style="display: flex;">
      <div class="game_item" style="width: 50%;" v-for="item in item_row_2" v-bind:key="item">
	<GameItem
	  v-bind:img_file="item.image_file"
	  v-bind:title="item.title"
          v-bind:url="item.url"
          v-bind:ins_index="item.ins_index"
          v-bind:is_show_button="1"
	  v-bind:title_font_size="1.2"
	  />
      </div>
    </div>

    <div style="display: flex;">
      <div class="game_item" style="width: 50%;" v-for="item in item_row_3" v-bind:key="item">
	<GameItem
	  v-bind:img_file="item.image_file"
	  v-bind:title="item.title"
          v-bind:url="item.url"
          v-bind:ins_index="item.ins_index"
          v-bind:is_show_button="1"
	  v-bind:title_font_size="1.2"
	  />
      </div>
    </div>

    <div style="display: flex;">
      <div class="game_item" style="width: 50%;" v-for="item in item_row_4" v-bind:key="item">
	<GameItem
	  v-bind:img_file="item.image_file"
	  v-bind:title="item.title"
          v-bind:url="item.url"
          v-bind:ins_index="item.ins_index"
          v-bind:is_show_button="1"
	  v-bind:title_font_size="1.2"
	  />
      </div>
    </div>

  </div>
  <div v-else> <!-- mobile -->

    <div style="display: flex;">
      <div class="game_item_mobile" style="width: 50%;" v-for="item in item_row_mobile_1" v-bind:key="item">
	<GameItem
	  v-bind:img_file="item.image_file"
	  v-bind:title="item.title"
          v-bind:url="item.url"
          v-bind:ins_index="item.ins_index"
          v-bind:is_show_button="1"
	  v-bind:title_font_size="3.2"
	  />
      </div>
    </div>


    <div style="display: flex;">
      <div class="game_item_mobile" style="width: 50%;" v-for="item in item_row_mobile_2" v-bind:key="item">
	<GameItem
	  v-bind:img_file="item.image_file"
	  v-bind:title="item.title"
          v-bind:url="item.url"
          v-bind:ins_index="item.ins_index"
          v-bind:is_show_button="1"
	  v-bind:title_font_size="3.2"
	  />
      </div>
    </div>

    <div style="display: flex;">
      <div class="game_item_mobile" style="width: 50%;" v-for="item in item_row_mobile_3" v-bind:key="item">
	<GameItem
	  v-bind:img_file="item.image_file"
	  v-bind:title="item.title"
          v-bind:url="item.url"
          v-bind:ins_index="item.ins_index"
          v-bind:is_show_button="1"
	  v-bind:title_font_size="3.2"
	  />
      </div>
    </div>

    <div style="display: flex;">
      <div class="game_item_mobile" style="width: 50%;" v-for="item in item_row_mobile_4" v-bind:key="item">
	<GameItem
	  v-bind:img_file="item.image_file"
	  v-bind:title="item.title"
          v-bind:url="item.url"
          v-bind:ins_index="item.ins_index"
          v-bind:is_show_button="1"
	  v-bind:title_font_size="3.2"
	  />
      </div>
    </div>

    <div style="display: flex;">
      <div class="game_item_mobile" style="width: 50%;" v-for="item in item_row_mobile_5" v-bind:key="item">
	<GameItem
	  v-bind:img_file="item.image_file"
	  v-bind:title="item.title"
          v-bind:url="item.url"
          v-bind:ins_index="item.ins_index"
          v-bind:is_show_button="1"
	  v-bind:title_font_size="3.2"
	  />
      </div>
    </div>

    <div style="display: flex;">
      <div class="game_item_mobile" style="width: 50%;" v-for="item in item_row_mobile_6" v-bind:key="item">
	<GameItem
	  v-bind:img_file="item.image_file"
	  v-bind:title="item.title"
          v-bind:url="item.url"
          v-bind:ins_index="item.ins_index"
          v-bind:is_show_button="1"
	  v-bind:title_font_size="3.2"
	  />
      </div>
    </div>

    <div style="display: flex;">
      <div class="game_item_mobile" style="width: 50%;" v-for="item in item_row_mobile_7" v-bind:key="item">
	<GameItem
	  v-bind:img_file="item.image_file"
	  v-bind:title="item.title"
          v-bind:url="item.url"
          v-bind:ins_index="item.ins_index"
          v-bind:is_show_button="1"
	  v-bind:title_font_size="3.2"
	  />
      </div>
    </div>

    <div style="display: flex;">
      <div class="game_item_mobile" style="width: 50%;" v-for="item in item_row_mobile_8" v-bind:key="item">
	<GameItem
	  v-bind:img_file="item.image_file"
	  v-bind:title="item.title"
          v-bind:url="item.url"
          v-bind:ins_index="item.ins_index"
          v-bind:is_show_button="1"
	  v-bind:title_font_size="3.2"
	  />
      </div>
    </div>


  </div>

</div>

</template>

<script>
import GameItem from '@/components/GameItem.vue'
import * as platform from '@/components/platform.js'
import * as game_data from '@/components/game_data.js'

export default{
    name: 'Games',
    components: {
	GameItem,
    },
    data(){
	return{
	    arenamain_url: "https://arenamain.id",
	    content_view: document.getElementById('content_view'),
	    is_mobile: false,
	    arenamain_logo: require('@/assets/images/arenamain_logo.png'),
	    header: require('@/assets/images/games/header.png'),
	    header_m: require('@/assets/images/games/header_m.png'),
	    item_row_1: [
                game_data.data[0],
                game_data.data[1],
                game_data.data[2],
                game_data.data[3],
	    ],
	    item_row_2: [
                game_data.data[4],
                game_data.data[5],
                game_data.data[6],
                game_data.data[7],
	    ],
	    item_row_3: [
                game_data.data[8],
                game_data.data[9],
                game_data.data[10],
                game_data.data[11],
	    ],
	    item_row_4: [
                game_data.data[12],
                game_data.data[13],
                game_data.data[14],
                game_data.data[15],
	    ],
	    item_row_mobile_1: [
                game_data.data[0],
                game_data.data[1],
	    ],
	    item_row_mobile_2: [
                game_data.data[2],
                game_data.data[3],
	    ],
	    item_row_mobile_3: [
                game_data.data[4],
                game_data.data[5],
	    ],
	    item_row_mobile_4: [
                game_data.data[6],
                game_data.data[7],
	    ],
	    item_row_mobile_5: [
                game_data.data[8],
                game_data.data[9],
	    ],
	    item_row_mobile_6: [
                game_data.data[10],
                game_data.data[11],
	    ],
	    item_row_mobile_7: [
                game_data.data[12],
                game_data.data[13],
	    ],
	    item_row_mobile_8: [
                game_data.data[14],
                game_data.data[15],
	    ],

	    pattern1: require('@/assets/images/pattern1.png'),
	}
    },
    mounted(){
	this.content_view.scrollTop = 0; // scroll to top of page
	this.is_mobile = platform.detect_mobile();
        this.on_resize();
    },
    created() {
      window.addEventListener("resize", this.on_resize);
    },
    unmounted() {
      window.removeEventListener("resize", this.on_resize);
    },
    methods: {
    on_resize() {
      if(window.innerWidth <= 1024){
        this.is_mobile=true;
      }
      else{
        this.is_mobile=false;
      }
    }
  }

}
</script>

<style>
</style>
