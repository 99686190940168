<template>

  <!-- 
       kevin haryo kuncoro
       kevinhyokun91@gmail.com
       kevin.haryo@timobile.tech
    -->

<!-- section 1 -->
<div class="pattern_bg_white_x" style="width: 100%; background-color: #abbcd6;">
  <div v-if="!is_mobile">

    <div style="user-select: none; font-weight: bold; font-size: 4vw; color: rgba(0, 0, 0, 0.0)"> ENTER AJA, Just new line ha hi hu</div>
    <div style="width: 100%; height: auto; background-color: none; margin-bottom: 4%;">
      <img v-bind:src="arenamain_logo" style="width: 35%; height: auto;" class="arenamain_touchpoint" onclick="show_arenamain_anchor()"/>
    </div>

    <div style="width: 100%; height: auto; display: flex; background-color: none">

      <div style="width: 50%; height: auto; background-color: none; color: white; font-family: bs_condensed; margin-top: 3%; margin-bottom: 12%;">

        <div style="margin-left: 20%; margin-top: 5%;  width: 97%; height: auto; background-color: none;">

          <div class="display_center_x" style="text-align: center; width: 70%; user-select: none; font-weight: bold; font-size: 11vw; letter-spacing: 3px;">
            INCREASE
          </div>
          <div class="display_center_x" style="width: 70%; height: auto; background-color: none; display: flex;">
            <div style="width: 60%; height: 100px; background-color: none;">
              <div style="width: 100%; display: flex">
                <div style="width: 20%">
                  <img v-bind:src="star_ptr" style="width: 80%; height: auto;" />
                </div>
                <div class="display_center_x" style="text-align: left; width: 80%; user-select: none; font-weight: bold; font-size: 2vw; letter-spacing: 2px;">
                  Engagement
                </div>
              </div>
            </div>

            <div style="width: 60%; height: 100px; background-color: none;">
              <div style="margin-left: 24%; width: 100%; display: flex">
                <div style=" width: 20%">
                  <img v-bind:src="star_ptr" style="width: 80%; height: auto;" />
                </div>
                <div class="display_center_x" style="text-align: left; width: 80%; user-select: none; font-weight: bold; font-size: 2vw; letter-spacing: 2px;">
                  Retention
                </div>
              </div>
            </div>

            <div style="width: 60%; height: 100px; background-color: none;">
              <div style="margin-left: 38%; width: 100%; display: flex">
                <div style="width: 20%">
                  <img v-bind:src="star_ptr" style="width: 80%; height: auto;" />
                </div>
                <div class="display_center_x" style="text-align: left; width: 80%; user-select: none; font-weight: bold; font-size: 2vw; letter-spacing: 2px;">
                  Revenue
                </div>
              </div>
            </div>

          </div>

        </div>

      </div>

      <div style="width: 50%; height: auto; background-color: none">
        <img v-bind:src="img_1" style="width: 53%; height: auto; margin-right: 15%" />
      </div>

    </div>

  </div>
  <div v-else>

    <div style="user-select: none; font-weight: bold; font-size: 6.4vw; color: rgba(0, 0, 0, 0.0)"> ENTER AJA, Just new line ha hi hu</div>

    <div style="width: 100; height: auto; background-color: none">
      <img v-bind:src="arenamain_logo" style="width: 70%; height: auto;" class="arenamain_touchpoint" onclick="show_arenamain_anchor()"/>
    </div>
    <div style="width: 100; height: auto; background-color: none">
      <img v-bind:src="img_1" style="width: 70%; height: auto;" />
    </div>
    <div style="width: 100%; height: auto; background-color: none; color: white; font-family: bs_condensed">
    

       <div class="display_center_x" style="text-align: center; width: 90%; user-select: none; font-weight: bold; font-size: 23vw; letter-spacing: 3px;">
         INCREASE
       </div>

          <div class="display_center_x" style="width: 80%; height: auto; background-color: none; display: flex;">

            <div style="width: 59%; height: 100px; background-color: none;">
              <div style="width: 100%; display: flex">
                <div style="width: 20%">
                  <img v-bind:src="star_ptr" style="width: 80%; height: auto;" />
                </div>
                <div class="display_center_x" style="text-align: left; width: 80%; user-select: none; font-weight: bold; font-size: 4.6vw; letter-spacing: 2px;">
                  Engagement
                </div>
              </div>
            </div>

            <div style="width: 59%; height: 100px; background-color: none;">
              <div style="margin-left: 12%; width: 100%; display: flex">
                <div style=" width: 20%">
                  <img v-bind:src="star_ptr" style="width: 80%; height: auto;" />
                </div>
                <div class="display_center_x" style="text-align: left; width: 80%; user-select: none; font-weight: bold; font-size: 4.6vw; letter-spacing: 2px;">
                  Retention
                </div>
              </div>
            </div>

            <div style="width: 59%; height: 100px; background-color: none;">
              <div style="margin-left: 17%; width: 100%; display: flex">
                <div style="width: 20%">
                  <img v-bind:src="star_ptr" style="width: 80%; height: auto;" />
                </div>
                <div class="display_center_x" style="text-align: left; width: 80%; user-select: none; font-weight: bold; font-size: 4.6vw; letter-spacing: 2px;">
                  Revenue
                </div>
              </div>
            </div>

          </div>


    </div>
  </div>

</div>

<div class="pattern_bg_m" style="width: 100%; height: auto; background-color: white; position: relative">
  <div v-if="!is_mobile">
      <img v-bind:src="pres1_b" class="pres"/>
      <div style="width: 100%; height: 100px; position: absolute; top: 90%; left: 0px;">
        <ButtonComponent v-bind:width="28" v-bind:type="1"/>
      </div>
  </div>
  <div v-else>
    <img v-bind:src="pres1_b_m" class="pres"/>
    <ButtonComponent v-bind:width="80" v-bind:type="1"/>
    <div style="user-select: none; font-weight: bold; font-size: 5vw; color: rgba(0, 0, 0, 0.0)"> ENTER AJA, Just new line ha hi hu</div>
  </div>
</div>

<!-- section 2 -->

<div class="pattern_bg_white_m" style="width: 100%; background-color: #abbcd6;">

  <div v-if="!is_mobile">

    <div style="user-select: none; font-weight: bold; font-size: 5vw; color: rgba(0, 0, 0, 0.0)"> ENTER AJA, Just new line ha hi hu</div>

    <div style="user-select: none; font-weight: bold; font-size: 4.7vw; color: #110d3a; font-family: bs_condensed">
      What You Get with
    </div>

    <img v-bind:src="arenamain_logo" style="width: 45%; height: auto;" class="arenamain_touchpoint" onclick="show_arenamain_anchor()"/>

    <div style="user-select: none; font-weight: bold; font-size: 3.5vw; color: rgba(0, 0, 0, 0.0)"> ENTER AJA, Just new line ha hi hu</div>

    <div class="display_center_x" style="width: 80%; heiight: 100%; background-color: none;">
      <div style="display: flex;">
	<div style="padding: 10px; width: 50%">
	  <img v-bind:src="sec2_1" class="pres" />
	</div>
	<div style="padding: 10px; width: 50%">
	  <img v-bind:src="sec2_2" class="pres" />
	</div>
	<div style="padding: 10px; width: 50%">
	  <img v-bind:src="sec2_3" class="pres" />
	</div>
      </div>
    </div>
    <div class="display_center_x" style="width: 80%; height: auto; background-color: none;">
      <div class="display_center_x" style="width: 80%; height: auto; background-color:none;">
        <div style="display: flex;">
	  <div class="display_center_x" style="padding: 10px; width: 41%">
	    <img v-bind:src="sec2_4" class="pres" />
	  </div>
	  <div class="display_center_x" style="padding: 10px; width: 41%">
	    <img v-bind:src="sec2_5" class="pres" />
	  </div>
        </div>
      </div>
    </div>


    <div style="user-select: none; font-weight: bold; font-size: 2vw; color: rgba(0, 0, 0, 0.0)"> ENTER AJA, Just new line ha hi hu</div>

    <ButtonComponent v-bind:width="28" />

    <div style="user-select: none; font-weight: bold; font-size: 3.5vw; color: rgba(0, 0, 0, 0.0)"> ENTER AJA, Just new line ha hi hu</div>

  </div>

  <div v-else><!-- mobile -->

    <div style="user-select: none; font-weight: bold; font-size: 7vw; color: rgba(0, 0, 0, 0.0)"> ENTER AJA, Just new line ha hi hu</div>

    <div class="display_center_x" style="user-select: none; font-weight: bold; font-size: 9vw; color: #110d3a; font-family: bs_condensed">
      What You Get with
    </div>

    <img v-bind:src="arenamain_logo" style="width: 90%; height: auto;" class="arenamain_touchpoint" onclick="show_arenamain_anchor()"/>

    <div style="user-select: none; font-weight: bold; font-size: 3.5vw; color: rgba(0, 0, 0, 0.0)"> ENTER AJA, Just new line ha hi hu</div>

    <div class="" style="width: 80%; background-color: none;">
      <div style="width: 100%; display: flex; flex-direction: column">

	  <img v-bind:src="sec2_1_m" class="pres" />
	  <img v-bind:src="sec2_2_m" class="pres" />
	  <img v-bind:src="sec2_3_m" class="pres" />
	  <img v-bind:src="sec2_4_m" class="pres" />
	  <img v-bind:src="sec2_5_m" class="pres" />

      </div>
    </div>
    
    <div style="user-select: none; font-weight: bold; font-size: 3.5vw; color: rgba(0, 0, 0, 0.0)"> ENTER AJA, Just new line ha hi hu</div>

    <ButtonComponent v-bind:width="90" />

    <div style="user-select: none; font-weight: bold; font-size: 3.5vw; color: rgba(0, 0, 0, 0.0)"> ENTER AJA, Just new line ha hi hu</div>

  </div>

</div>

<!-- section 3 -->

<div class="pattern_bg_m" style="width: 100%; background-color: white;">

  <div v-if="!is_mobile">

    <div style="user-select: none; font-weight: bold; font-size: 5vw; color: rgba(0, 0, 0, 0.0)"> ENTER AJA, Just new line ha hi hu</div>

    <div style="user-select: none; font-weight: bold; font-size: 4.7vw; color: #110d3a; font-family: bs_condensed">
      What Your Users Get with
    </div>

    <img v-bind:src="arenamain_logo" style="width: 45%; height: auto;" class="arenamain_touchpoint" onclick="show_arenamain_anchor()"/>

    <div style="user-select: none; font-weight: bold; font-size: 5vw; color: rgba(0, 0, 0, 0.0)"> ENTER AJA, Just new line ha hi hu</div>

    <div class="display_center_x" style="width: 73%">
      <img v-bind:src="pres3" class="pres" />
    </div>

    <div style="user-select: none; font-weight: bold; font-size: 3.5vw; color: rgba(0, 0, 0, 0.0)"> ENTER AJA, Just new line ha hi hu</div>

    <ButtonComponent v-bind:width="28" />

    <div style="user-select: none; font-weight: bold; font-size: 3.5vw; color: rgba(0, 0, 0, 0.0)"> ENTER AJA, Just new line ha hi hu</div>

  </div>
  <div v-else> <!-- mobile -->

    <div style="user-select: none; font-weight: bold; font-size: 7vw; color: rgba(0, 0, 0, 0.0)"> ENTER AJA, Just new line ha hi hu</div>

    <div style="user-select: none; font-weight: bold; font-size: 9vw; color: #110d3a; font-family: bs_condensed">
      What Your Users Get with
    </div>

    <img v-bind:src="arenamain_logo" style="width: 90%; height: auto;" class="arenamain_touchpoint" onclick="show_arenamain_anchor()" />

    <div style="user-select: none; font-weight: bold; font-size: 3.5vw; color: rgba(0, 0, 0, 0.0)"> ENTER AJA, Just new line ha hi hu</div>

    <div class="display_center_x" style="width: 90%">
      <img v-bind:src="sec3_m" class="pres" />
    </div>

    <div style="user-select: none; font-weight: bold; font-size: 3.5vw; color: rgba(0, 0, 0, 0.0)"> ENTER AJA, Just new line ha hi hu</div>

    <ButtonComponent v-bind:width="90" />

    <div style="user-select: none; font-weight: bold; font-size: 3.5vw; color: rgba(0, 0, 0, 0.0)"> ENTER AJA, Just new line ha hi hu</div>

  </div>

</div>

<!-- section 4 -->
<div class="pattern_bg_white_x" style="width: 100%; background-color: #abbcd6;">
  <div v-if="!is_mobile">

    <div style="user-select: none; font-weight: bold; font-size: 3vw; color: rgba(0, 0, 0, 0.0)"> ENTER AJA, Just new line ha hi hu</div>

    <img v-bind:src="arenamain_logo" style="width: 45%; height: auto;" class="arenamain_touchpoint" onclick="show_arenamain_anchor()"/>

    <div style="user-select: none; font-weight: bold; font-size: 5vw; font-family: bs_condensed; color: #110d3a">
      In a nutshell
    </div>

    <div style="user-select: none; font-weight: bold; font-size: 4vw; color: rgba(0, 0, 0, 0.0)"> ENTER AJA, Just new line ha hi hu</div>

    <div class="display_center_x" style="width: 93%">
      <div style="width: 100%; display: flex">

	<div style="padding: 10px; width: 30%">
	  <img class="pres" v-bind:src="sec4_1" />
	</div>
	<div style="padding: 10px; width: 30%">
	  <img class="pres" v-bind:src="sec4_2" />
	</div>
	<div style="padding: 10px; width: 30%">
	  <img class="pres" v-bind:src="sec4_3" />
	</div>
	<div style="padding: 10px; width: 30%">
	  <img class="pres" v-bind:src="sec4_4" />
	</div>
	<div style="padding: 10px; width: 30%">
	  <img class="pres" v-bind:src="sec4_5" />
	</div>
	<div style="padding: 10px; width: 30%">
	  <img class="pres" v-bind:src="sec4_6" />
	</div>

      </div>
    </div>

    <ButtonComponent v-bind:width="28" />

    <div style="user-select: none; font-weight: bold; font-size: 3.5vw; color: rgba(0, 0, 0, 0.0)"> ENTER AJA, Just new line ha hi hu</div>

  </div>
  <div v-else> <!-- mobile -->

    <div style="user-select: none; font-weight: bold; font-size: 7vw; color: rgba(0, 0, 0, 0.0)"> ENTER AJA, Just new line ha hi hu</div>

    <img v-bind:src="arenamain_logo" style="width: 90%; height: auto;" class="arenamain_touchpoint" onclick="show_arenamain_anchor()"/>

    <div style="user-select: none; font-weight: bold; font-size: 9vw; font-family: bs_condensed; color: #110d3a">
      In a nutshell
    </div>

    <div style="user-select: none; font-weight: bold; font-size: 5vw; color: rgba(0, 0, 0, 0.0)"> ENTER AJA, Just new line ha hi hu</div>

    <div class="display_center_x" style="width: 98%">
      <div style="width: 100%; display: flex; flex-direction: column">

	<div style="width: 100%; display: flex;">
	  <div style="width: 50%">
	    <img class="pres" v-bind:src="sec4_1" />
	  </div>
	  <div style="width: 3%;" />
	  <div style="width: 50%">
	    <img class="pres" v-bind:src="sec4_2" />
	  </div>
	</div>


	<div style="width: 100%; display: flex; margin-top: 15%">
	  <div style="width: 50%">
	    <img class="pres" v-bind:src="sec4_3" />
	  </div>
	  <div style="width: 3%;" />
	  <div style="width: 50%">
	    <img class="pres" v-bind:src="sec4_4" />
	  </div>
	</div>


	<div style="width: 100%; display: flex; margin-top: 10%">
	  <div style="width: 50%">
	    <img class="pres" v-bind:src="sec4_5" />
	  </div>
	  <div style="width: 3%;" />
	  <div style="width: 50%">
	    <img class="pres" v-bind:src="sec4_6" />
	  </div>
	</div>
	
      </div>
    </div>

    <div style="user-select: none; font-weight: bold; font-size: 7vw; color: rgba(0, 0, 0, 0.0)"> ENTER AJA, Just new line ha hi hu</div>

    <ButtonComponent v-bind:width="90" />

    <div style="user-select: none; font-weight: bold; font-size: 3.5vw; color: rgba(0, 0, 0, 0.0)"> ENTER AJA, Just new line ha hi hu</div>

  </div>
</div>

<!-- try me... -->

<!-- <div v-bind:style="{width: '100%', backgroundColor: 'gray',backgroundImage: 'url(' + pattern1 + ')', backgroundSize: '532px 461px' }"> -->
<div class="pattern_bg_white_x" style="width: 100%; height: auto; background-color: gray;">

  <div style="width: 100%" v-if="!is_mobile">
    <div style="width: 100%; height: 70px; background-color: none;"></div>
      <div style="width: 70%; height: auto; background-color: none;" class="display_center_x">
  
        <div style="display: flex;">
          <div class="game_item_m" style="width: 50%;" v-for="item in item_row_1" v-bind:key="item">
            <GameItem
              v-bind:img_file="item.image_file"
              v-bind:title="item.title"
              />
          </div>
        </div>
  
        <div style="display: flex;">
          <div class="game_item_m" style="width: 50%;" v-for="item in item_row_2" v-bind:key="item">
            <GameItem
              v-bind:img_file="item.image_file"
              v-bind:title="item.title"
              />
          </div>
        </div>
  
        <div style="display: flex;">
          <div class="game_item_m" style="width: 50%;" v-for="item in item_row_3" v-bind:key="item">
            <GameItem
              v-bind:img_file="item.image_file"
              v-bind:title="item.title"
              />
          </div>
        </div>
  
        <div style="display: flex;">
          <div class="game_item_m" style="width: 50%;" v-for="item in item_row_4" v-bind:key="item">
            <GameItem
              v-bind:img_file="item.image_file"
              v-bind:title="item.title"
              />
          </div>
        </div>
  
      </div>
  
      <div style="width: 100%; height: auto; background-color: none;">
        <div style="width: 70%; height: auto; background-color: none;" class="display_center_x">
  
        <img v-bind:src="btn_try_me" style="width: 75%; height: auto;" class="arenamain_touchpoint" onclick="show_arenamain_anchor()"/>
  
        </div>
      </div>

      <div style="width: 100%; height: 100px; background-color: none;"></div>
  </div>
<!-- mobile -->
<div style="width: 100%;" v-else>
  <div style="width: 90%; height: auto; background-color: none;" class="display_center_x">

    <div style="width: 100%; height: 30px; background-color: none;"></div>

    <div style="display: flex;">
      <div class="game_item_mobile_no_margin" style="width: 50%;" v-for="item in item_row_mobile_1" v-bind:key="item">
	<GameItem
	  v-bind:img_file="item.image_file"
	  v-bind:title="item.title"
	  />
      </div>
    </div>

    <div style="display: flex;">
      <div class="game_item_mobile_no_margin" style="width: 50%;" v-for="item in item_row_mobile_2" v-bind:key="item">
	<GameItem
	  v-bind:img_file="item.image_file"
	  v-bind:title="item.title"
	  />
      </div>
    </div>

    <div style="display: flex;">
      <div class="game_item_mobile_no_margin" style="width: 50%;" v-for="item in item_row_mobile_3" v-bind:key="item">
	<GameItem
	  v-bind:img_file="item.image_file"
	  v-bind:title="item.title"
	  />
      </div>
    </div>

    <div style="display: flex;">
      <div class="game_item_mobile_no_margin" style="width: 50%;" v-for="item in item_row_mobile_4" v-bind:key="item">
	<GameItem
	  v-bind:img_file="item.image_file"
	  v-bind:title="item.title"
	  />
      </div>
    </div>

    <div style="display: flex;">
      <div class="game_item_mobile_no_margin" style="width: 50%;" v-for="item in item_row_mobile_5" v-bind:key="item">
	<GameItem
	  v-bind:img_file="item.image_file"
	  v-bind:title="item.title"
	  />
      </div>
    </div>

    <div style="display: flex;">
      <div class="game_item_mobile_no_margin" style="width: 50%;" v-for="item in item_row_mobile_6" v-bind:key="item">
	<GameItem
	  v-bind:img_file="item.image_file"
	  v-bind:title="item.title"
	  />
      </div>
    </div>

    <div style="display: flex;">
      <div class="game_item_mobile_no_margin" style="width: 50%;" v-for="item in item_row_mobile_7" v-bind:key="item">
	<GameItem
	  v-bind:img_file="item.image_file"
	  v-bind:title="item.title"
	  />
      </div>
    </div>

    <div style="display: flex;">
      <div class="game_item_mobile_no_margin" style="width: 50%;" v-for="item in item_row_mobile_8" v-bind:key="item">
	<GameItem
	  v-bind:img_file="item.image_file"
	  v-bind:title="item.title"
	  />
      </div>
    </div>

  </div>

  <div style="width: 100%; height: auto; background-color: none;">
    <img v-bind:src="btn_try_me" style="width: 100%; height: auto;" class="arenamain_touchpoint" onclick="show_arenamain_anchor()"/>
  </div>

  <div style="width: 100%; height: 30px; background-color: none;"></div>

</div>

</div>

<img v-bind:src="pres4" class="pres" />

<!-- section 5 -->
<div class="pattern_bg_m" style="width: 100%; background-color: white;">
  <div v-if="!is_mobile">

    <div style="user-select: none; font-weight: bold; font-size: 5vw; color: rgba(0, 0, 0, 0.0)"> ENTER AJA, Just new line ha hi hu</div>

    <img v-bind:src="arenamain_logo" style="width: 45%; height: auto;" class="arenamain_touchpoint" onclick="show_arenamain_anchor()"/>

    <div style="user-select: none; font-weight: bold; font-size: 5vw; color: #110d3a; font-family: bs_condensed;">
      Partnership Mechanism
    </div>

    <div style="user-select: none; font-weight: bold; font-size: 5vw; color: rgba(0, 0, 0, 0.0)"> ENTER AJA, Just new line ha hi hu</div>

    <div class="display_center_x" style="width: 80%">
      <div style="width: 100%; display: flex;">
	<div style="width: 50%">
	    <img class="pres" v-bind:src="sec5_1" />
	</div>
	<div style="width: 5%" />
	<div style="width: 50%">
	    <img class="pres" v-bind:src="sec5_2" />
	</div>
      </div>
    </div>


    <div style="user-select: none; font-weight: bold; font-size: 5vw; color: rgba(0, 0, 0, 0.0)"> ENTER AJA, Just new line ha hi hu</div>

    <ButtonComponent v-bind:width="28" />

    <div style="user-select: none; font-weight: bold; font-size: 3.5vw; color: rgba(0, 0, 0, 0.0)"> ENTER AJA, Just new line ha hi hu</div>

  </div>
  <div v-else> <!-- mobile -->

    <div style="user-select: none; font-weight: bold; font-size: 7vw; color: rgba(0, 0, 0, 0.0)"> ENTER AJA, Just new line ha hi hu</div>

    <img v-bind:src="arenamain_logo" style="width: 90%; height: auto;" class="arenamain_touchpoint" onclick="show_arenamain_anchor()"/>

    <div style="user-select: none; font-weight: bold; font-size: 9vw; color: #110d3a; font-family: bs_condensed;">
      Partnership Mechanism
    </div>

    <div class="display_center_x" style="width: 95%">
      <div style="width: 100%; display: flex; flex-direction: column;">

	<div style="position: relative">
	    <img class="pres" v-bind:src="sec5_1" />
	</div>

	<div style="user-select: none; font-weight: bold; font-size: 5vw; color: rgba(0, 0, 0, 0.0)"> ENTER AJA, Just new line ha hi hu</div>

	<div style="position: relative">
	    <img class="pres" v-bind:src="sec5_2" />
	</div>

      </div>
    </div>



    <ButtonComponent v-bind:width="90" style="margin-top: 10%; margin-bottom: 10%"/>


  </div>
</div>

</template>

<script>
import ButtonComponent from '@/components/ButtonComponent.vue'
import GameItem from '@/components/GameItem.vue'
import * as platform from '@/components/platform.js'
import * as game_data from '@/components/game_data.js'

export default{
    name: 'arenamain',
    components: {
	ButtonComponent,
	GameItem,
    },
    data(){
	return{
	    arenamain_url: 'https://arenamain.id',
	    arenamain_category_url: 'https://arenamain.id/category/action',
	    content_view: document.getElementById('content_view'),
	    is_mobile: false,
	    arenamain_logo: require('@/assets/images/arenamain_logo.png'),
	    img_1: require('@/assets/images/arenamain/img_1_.png'),
	    pres1_b: require('@/assets/images/arenamain/img_1_b_.png'),
	    pres1_b_m: require('@/assets/images/arenamain_m/sec1_b_m.png'),
	    sec2_1: require('@/assets/images/arenamain/sec2_1_.png'),
	    sec2_2: require('@/assets/images/arenamain/sec2_2_.png'),
	    sec2_3: require('@/assets/images/arenamain/sec2_3_.png'),
	    sec2_4: require('@/assets/images/arenamain/sec2_4_.png'),
	    sec2_5: require('@/assets/images/arenamain/sec2_5_.png'),
	    sec2_1_m: require('@/assets/images/arenamain_m/sec2_1_m.png'),
	    sec2_2_m: require('@/assets/images/arenamain_m/sec2_2_m.png'),
	    sec2_3_m: require('@/assets/images/arenamain_m/sec2_3_m.png'),
	    sec2_4_m: require('@/assets/images/arenamain_m/sec2_4_m.png'),
	    sec2_5_m: require('@/assets/images/arenamain_m/sec2_5_m.png'),
	    sec4_1: require('@/assets/images/arenamain/sec4_1_.png'),
	    sec4_2: require('@/assets/images/arenamain/sec4_2_.png'),
	    sec4_3: require('@/assets/images/arenamain/sec4_3_.png'),
	    sec4_4: require('@/assets/images/arenamain/sec4_4_.png'),
	    sec4_5: require('@/assets/images/arenamain/sec4_5_.png'),
	    sec4_6: require('@/assets/images/arenamain/sec4_6_.png'),
	    sec5_1: require('@/assets/images/arenamain/sec5_1_.png'),
	    sec5_2: require('@/assets/images/arenamain/sec5_2_.png'),
	    pres3: require('@/assets/images/arenamain/img_3_.png'),
	    sec3_m: require('@/assets/images/arenamain_m/sec3_m.png'),

            item_row_1: [
                game_data.data[0],
                game_data.data[1],
                game_data.data[2],
                game_data.data[3],
            ],
            item_row_2: [
                game_data.data[4],
                game_data.data[5],
                game_data.data[6],
                game_data.data[7],
            ],
            item_row_3: [
                game_data.data[8],
                game_data.data[9],
                game_data.data[10],
                game_data.data[11],
            ],
            item_row_4: [
                game_data.data[12],
                game_data.data[13],
                game_data.data[14],
                game_data.data[15],
            ],

	    item_row_mobile_1: [
                game_data.data[0],
                game_data.data[1],
	    ],
	    item_row_mobile_2: [
                game_data.data[2],
                game_data.data[3],
	    ],
	    item_row_mobile_3: [
                game_data.data[4],
                game_data.data[5],
	    ],
	    item_row_mobile_4: [
                game_data.data[6],
                game_data.data[7],
	    ],
	    item_row_mobile_5: [
                game_data.data[8],
                game_data.data[9],
	    ],
	    item_row_mobile_6: [
                game_data.data[10],
                game_data.data[11],
	    ],
	    item_row_mobile_7: [
                game_data.data[12],
                game_data.data[13],
	    ],
	    item_row_mobile_8: [
                game_data.data[14],
                game_data.data[15],
	    ],

	    btn_try_me: require('@/assets/images/arenamain/btn_try_me.png'),
	    car_img: require('@/assets/images/homepage/car_.png'),
	    star_ptr: require('@/assets/images/star_pointer.png'),
	    pattern1: require('@/assets/images/pattern1b.png'),
	}
    },
    mounted(){
       this.content_view.scrollTop = 0; // scroll to top of page
       this.is_mobile = platform.detect_mobile();
       this.on_resize();
    },
    created() {
      window.addEventListener("resize", this.on_resize);
    },
    unmounted() {
      window.removeEventListener("resize", this.on_resize);
    },
    methods: {
    on_resize() {
      if(window.innerWidth <= 1024){
        this.is_mobile=true;
      }
      else{
        this.is_mobile=false;
      }
    }
  }
}
</script>
