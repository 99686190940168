/*
  kevin haryo kuncoro
  kevinhyokun91@gmail.com
  kevin.haryo@gamotions.com
*/

export var data=[
// action
{title: 'TEMBAK', image_file: 'gi_tembak.png', url: 'https://arenamain.id/game/tembak', ins_index: 1},
{title: 'METEOR', image_file: 'gi_meteor.png', url: 'https://arenamain.id/game/meteor', ins_index: 2},
{title: 'PERANG GALAKSI', image_file: 'gi_peranggalaksi.png', url: 'https://arenamain.id/game/perang-galaksi', ins_index: 3},
{title: 'TANK TEMPUR', image_file: 'gi_tanktempur.png', url: 'https://arenamain.id/game/tank-tempur', ins_index: 4},

// puzzle
{title: 'PUZZLE KUBUS', image_file: 'gi_puzzlekubus.png', url: 'https://arenamain.id/game/puzzle-kubus', ins_index: 5},
{title: 'JUNGLE 2048', image_file: 'gi_junggle2048.png', url: 'https://arenamain.id/game/jungle-2048', ins_index: 6},
{title: 'TUANG SEWARNA', image_file: 'gi_tuangsewarna.png', url: 'https://arenamain.id/game/tuang-sewarna', ins_index: 7},
{title: 'CAT DEKOR', image_file: 'gi_catdekor.png', url: 'https://arenamain.id/game/cat-dekor', ins_index: 8},

// racing
{title: 'BEBAS HAMBATAN', image_file: 'gi_bebashambatan.png', url: 'https://arenamain.id/game/bebas-hambatan', ins_index: 9},
{title: 'MOBIL GURUN', image_file: 'gi_mobilgurun.png', url: 'https://arenamain.id/game/mobil-gurun', ins_index: 10},
{title: 'BALAP LIAR', image_file: 'gi_balapliar.png', url: 'https://arenamain.id/game/balap-liar', ins_index: 11},
{title: 'BALAP MAUT', image_file: 'gi_balapmaut.png', url: 'https://arenamain.id/game/balap-maut', ins_index: 12},

// casual
{title: 'AYAM JAGOAN', image_file: 'gi_ayamjagoan.png', url: 'https://arenamain.id/game/ayam-jagoan', ins_index: 13},
{title: 'GALI YUK!', image_file: 'gi_galiyuk.png', url: 'https://arenamain.id/game/gali-yuk', ins_index: 14},
{title: 'MACET', image_file: 'gi_macet.png', url: 'https://arenamain.id/game/macet', ins_index: 15},
{title: 'ULAR TAMBAH', image_file: 'gi_ulartambah.png', url: 'https://arenamain.id/game/ular-tambah', ins_index: 16},
];
