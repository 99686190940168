<template>

  <!-- 
       kevin haryo kuncoro
       kevinhyokun91@gmail.com
       kevin.haryo@timobile.tech
    -->

  <div class="header">

    <div class="display_center_x" style="width: 916px; height: 100%; background-color: none; display: flex;">

      <!-- logo -->

      <div style="width:30%; height: 100%; background-color: none;">
	<router-link to="/" style="text-decoration: none;">
	  <div style="width: 100%; height: 100%; background-color: none;">
	    <img v-bind:src="logo_img" class="pres display_center" />
	  </div>
	</router-link>
      </div>

      <div style="width:70%; height: 100%; background-color: none;">
	<nav class="display_center" style="display: flex; height: 100%; float: left; margin-left: 10px; background-color: none;">

	  <router-link to="/arenamain" style="text-decoration: none;">
	    <div class="menu_item_hr" style="height: 100%;">
	      <div class="display_center">Arena Main</div>
	    </div>
	  </router-link>

	  <router-link to="/games" style="text-decoration: none;">
	    <div class="menu_item_hr" style="height: 100%;">
	      <div class="display_center">Games</div>
	    </div>
	  </router-link>

	  <router-link to="/careers" style="text-decoration: none;">
	    <div class="menu_item_hr" style="height: 100%;">
	      <div class="display_center">Careers</div>
	    </div>
	  </router-link>

	  <router-link to="/contact" style="text-decoration: none;">
	    <div class="menu_item_hr" style="height: 100%;">
	      <div class="display_center">Contact</div>
	    </div>
	  </router-link>

	  <router-link to="/about" style="text-decoration: none;">
	  <div class="menu_item_hr" style="height: 100%;">
	    <div class="display_center">About</div>
	  </div>
	  </router-link>

	</nav>
      </div>

    </div>

  </div>

</template>

<script>
import './style.css';

export default{
    name: 'header',
    data(){
	return{
	    logo_img: require('@/assets/images/logo.png'),
	}
    },
}

</script>
