<template>

  <!-- 
       kevin haryo kuncoro
       kevinhyokun91@gmail.com
       kevin.haryo@timobile.tech
    -->

  <div class="footer" v-if="!is_mobile">
    <div class="display_center_x" style="width: 50%; height: 100%; background-color: none;">

      <div class="display_center_x" style="width: 45%; height: 35%; background-color: none; display: flex;">

	<div style="width: 20%; height: 100%; background-color: none;">
	  <a href="https://www.linkedin.com/company/gamotions/" target="_blank">
	    <img v-bind:src="linkedin_logo" style="width: 60%; height: auto;" class="display_center"/>
	  </a>

	</div>

	<div style="width: 40%; height: 100%; background-color: none; font-size: 1.2vw">
	<router-link to="/contact" style="text-decoration: none;">
	  <div class="display_center" style="user-select: none; color: #F9FAFF; ">
	    Contact Us
	  </div>
	</router-link>
	</div>

	<div style="width: 40%; height: 100%; background-color: none; font-size: 1.2vw">
	<router-link to="/privacypolicy" style="text-decoration: none;">
	  <div class="display_center" style="user-select: none; color: #F9FAFF;">
	    Privacy Policy
	  </div>
	</router-link>
	</div>
	  
      </div>

      <div style="width: 100%; height: 90px; background-color: none;">
	<router-link to="/" style="text-decoration: none;">
	  <img v-bind:src="gamotions_logo" style="width: auto ; height: 100%;" class="display_center display_center_x"/>
	</router-link>
      </div>

      <div style="user-select: none; font-weight: bold; font-size: 2vw; color: rgba(0, 0, 0, 0.0)"> ENTER AJA, Just new line ha hi hu</div>

      <hr style="border: 1px solid #89A9FF" />

      <div style="user-select: none; font-weight: bold; font-size: 2vw; color: rgba(0, 0, 0, 0.0)"> ENTER AJA, Just new line ha hi hu</div>
    
      <div style="user-select: none; color: #F9FAFF; font-size: 1vw">
	Copyright © {{ year }}. Gamotions - PT Arena Solusi Digital. All right reserved
      </div>

    </div>
  </div>
  <div class="footer" v-else> <!-- mobile -->

    <div class="display_center_x" style="width: 90%; height: 100%; background-color: none;">

      <div class="display_center_x" style="width: 80%; height: 30%; background-color: none; display: flex;">

	<div style="width: 20%; height: 100%; background-color: none;">
	  <a href="https://www.linkedin.com/company/gamotions/" target="_blank">
	    <img v-bind:src="linkedin_logo" style="width: 60%; height: auto;" class="display_center"/>
	  </a>

	</div>

	<div style="width: 40%; height: 100%; background-color: none; font-size: 4.2vw">
	<router-link to="/contact" style="text-decoration: none;">
	  <div class="display_center" style="user-select: none; color: #F9FAFF;">
	    Contact Us
	  </div>
	</router-link>
	</div>

	<div style="width: 40%; height: 100%; background-color: none; font-size: 4.2vw">
	  <router-link to="/privacypolicy" style="text-decoration: none;">
	    <div class="display_center" style="user-select: none; color: #F9FAFF;">
	      Privacy Policy
	    </div>
	  </router-link>
	</div>
	  
      </div>

      <div style="width: 100%; height: 90px; background-color: none;">
	<img v-bind:src="gamotions_logo" style="width: auto ; height: 50%;" class="display_center display_center_x"/>
      </div>

      <div style="user-select: none; font-weight: bold; font-size: 5vw; color: rgba(0, 0, 0, 0.0)"> ENTER AJA, Just new line ha hi hu</div>

      <hr style="border: 1px solid #89A9FF" />

      <div style="user-select: none; font-weight: bold; font-size: 5vw; color: rgba(0, 0, 0, 0.0)"> ENTER AJA, Just new line ha hi hu</div>
    
      <div style="user-select: none; color: #F9FAFF; font-size: 3vw">
	Copyright © {{ year }}. Gamotions - PT Arena Solusi Digital.
      </div>
      <div style="user-select: none; color: #F9FAFF; font-size: 3vw">
	All right reserved
      </div>

    </div>

  </div>

</template>

<script>
import './style.css';
import * as platform from '@/components/platform.js'

export default{
    name: 'footer',
    data(){
	return{
	    year: '2022',
	    is_mobile: false,
	    linkedin_logo: require('@/assets/images/linkedin_logo.png'),
	    gamotions_logo: require('@/assets/images/logo_v.png'),
	}
    },
    mounted(){
	this.is_mobile = platform.detect_mobile();
        this.on_resize();
    },
    methods:{
	on_resize(){
	    this.window_width = window.innerWidth;
	    this.window_height = window.innerHeight;

            if(this.window_width <= 1024){
              this.is_mobile=true;
            }
            else{
              this.is_mobile=false;
            }
	},
    },
    created(){
	window.addEventListener("resize", this.on_resize);
    },
    unmounted(){
	window.removeEventListener("resize", this.on_resize);
    },
}

</script>
