<template>

  <!-- 
     kevin haryo kuncoro
     kevinhyokun91@gmail.com
     kevin.haryo@timobile.tech
  -->

    <div class="pattern_bg_white_x" style="background-color: #abbcd6;">

      <div style="width: 100%; height: 100px; background-color: none;"></div>

      <div v-if="!is_mobile"> 

        <div style="width: 100%; height: 100%; display: flex;">

          <div style="width: 50%; background-color: none; font-family: bs_condensed; color: #120f3a;">

          <div class="display_center_x" style="width: 78%; height: auto; background-color: none;">

            <img v-bind:src="img_1" style="width: 70%; height: auto;" />

            <div style="text-align: left; width: 100%; user-select: none; font-weight: bold; font-size: 5vw; ">
              DROP US A MESSAGE
            </div>

            <div style="text-align: left ;width: 100%; user-select: none; font-size: 3vw;">
             What Do You Want To 
            </div>

            <div style="display: flex;">
  	          <div class="checkbox_div">
  	            <input type="checkbox" id="checkbox" v-model="checked_option" v-bind:value="checked_str" class="cus_checkbox_percent" />
  	          </div>
  	          <div style="width: 3%"></div>
  	          <div>
  	            <label for="checkbox" style="user-select: none; font-size: 2vw;">
  	              {{ checked_str }}
  	            </label>
  	          </div>
  	        </div>
  
  	        <div style="display: flex;">
  	          <div class="checkbox_div">
  	            <input type="checkbox" id="checkbox2" v-model="checked_option" v-bind:value="checked2_str" class="cus_checkbox_percent" />
  	          </div>
  	          <div style="width: 3%"></div>
  	          <div>
  	            <label for="checkbox2" style="user-select: none; font-size: 2vw; ">
  	              {{ checked2_str }}
  	            </label>
  	          </div>
  	        </div>
  
  	        <div style="display: flex;">
  	          <div class="checkbox_div">
  	            <input type="checkbox" id="checkbox3" v-model="checked_option" v-bind:value="checked3_str" class="cus_checkbox_percent" />
  	          </div>
  	          <div style="width: 3%"></div>
  	          <div>
  	            <label for="checkbox3" style="user-select: none; font-size: 2vw;">
  	              {{ checked3_str }}
  	            </label>
  	          </div>
  	        </div>
  
  	        <div style="display: flex;">
  	          <div class="checkbox_div">
  	            <input type="checkbox" id="checkbox4" v-model="checked_option" v-bind:value="checked4_str" class="cus_checkbox_percent" />
  	          </div>
  	          <div style="width: 3%"></div>
  	          <div>
  	            <label for="checkbox4" style="user-select: none; font-size: 2vw;">
  	              {{ checked4_str }}
  	            </label>
  	          </div>
  	        </div>
  
  	        <div style="display: flex;">
  	          <div class="checkbox_div">
  	            <input type="checkbox" id="checkbox5" v-model="checked5" class="cus_checkbox_percent" />
  	          </div>
  	          <div style="width: 3%"></div>
  	          <div style="display:flex; flex-direction: column;">

  	            <label for="checkbox5" style="text-align: left; user-select: none; font-size: 2vw;">
  	              Other
  	            </label>

  	            <input v-model="other_option" style="border-radius: 11px; font-size: 1.2vw; width: 100%; height: 35px;" v-if="checked5"/>

  	          </div>
  	        </div>

              </div>

            
          </div>

          <div style="width: 50%; background-color: none">

            <div class="display_center_x" style="width: 80%; background-color: none;">

              <div style="width: 100%; display: flex; margin-top: 5.5%;">

                <div style="width: 50%;">
                  <div class="form-group">
                   <input type="text"  name="name" required="required" class="cus_text" style="font-size: 1.2vw" v-model="firstname" />
                   <label style="font-size: 1vw">First Name</label>
                  </div>
                </div>

                <div style="width: 10%;"></div>

                <div style="width: 50%;">
                  <div class="form-group">
                    <input type="text"  name="name" required="required" class="cus_text" style="font-size: 1.2vw" v-model="lastname" />
                    <label style="font-size: 1vw">Last Name</label>
                  </div>
                </div>
              </div>

              <div style="width: 100%; display: flex; margin-top: 5.5%">

                <div style="width: 50%;">
                  <div class="form-group">
                    <input type="text"  name="name" required="required" class="cus_text" style="font-size: 1.2vw" v-model="email"/>
                    <label style="font-size: 1vw">Email</label>
                  </div>
                </div>

                <div style="width: 10%;"></div>

                <div style="width: 50%;">
                  <div class="form-group">
                    <input type="tel"  name="name" required="required" class="cus_text only_num" style="font-size: 1.2vw" v-model="phone" />
                    <label style="font-size: 1vw">Phone Number</label>
                  </div>
                </div>

              </div>

              <div style="user-select: none; font-weight: bold; font-size: 1vw; color: rgba(0, 0, 0, 0.0)"> ENTER AJA, Just new line ha hi hu</div>

              <textarea v-model="message" placeholder="Your message here" style="font-family: Rubik; font-size: 1vw; width: 100%; height: 350px; border-radius: 11px; border:2px solid black; margin-top: 3%"></textarea>

              <div style="user-select: none; font-weight: bold; font-size: 1vw; color: rgba(0, 0, 0, 0.0)"> ENTER AJA, Just new line ha hi hu</div>

              <div style="display: flex;">
                <div style="font-size: 0.9vw; color: red">
                *
                </div>
                <div style="font-size: 0.9vw; color: black">
                  Must be filled in
                </div>
              </div>

            </div>

            <div style="user-select: none; font-size: 1.2vw; color: red; margin-bottom: 7%; " v-if="is_show_error">
              <div style="user-select: none; font-weight: bold; font-size: 4vw; color: rgba(0, 0, 0, 0.0)"> ENTER AJA </div>
              <div style="font-family: bs_condensed;" v-if="this.firstname == ''">Please fill in first name field</div>
              <div style="font-family: bs_condensed;" v-if="this.lastname == ''">Please fill in last name field</div>
              <div style="font-family: bs_condensed;" v-if="this.phone == ''">Please fill in phone field</div>
              <div style="font-family: bs_condensed;" v-if="this.email == ''">Please fill in email field</div>
              <div style="font-family: bs_condensed;" v-if="!this.validate_email(this.email)">Your email format is incorrect</div>
              <div style="font-family: bs_condensed;" v-if="this.checked_option.length == 0">Please select one or more options from the checkbox above </div>
            </div>

            <div style="user-select: none; font-size: 1.2vw; color: green; margin-bottom: 7%" v-if="is_show_success">
              <div style="font-family: bs_condensed; user-select: none; font-weight: bold; font-size: 4vw; color: rgba(0, 0, 0, 0.0)"> ENTER AJA </div>
                Your message was sent successfully
              </div>
              <img v-bind:src="btn_send" style="width: 20%; height: auto; margin-bottom: 10%;" v-on:click="on_send_clicked()" />

            </div>
          </div>

        </div> 

        <div v-else>

          <div style="width: 100%; display: flex; flex-direction: column;">
            <div class="display_center_x" style="width: 90%; background-color: none; font-family: bs_condensed; color: #120f3a;">

              <img v-bind:src="img_1" style="width: 90%; height: auto;" />

              <div style="text-align: left ;width: 100%; user-select: none; font-weight: bold; font-size: 9vw; ">
                DROP US A MESSAGE
              </div>

              <div style="text-align: left ;width: 100%; user-select: none; font-size: 7vw;">
               What Do You Want To 
              </div>

              <div style="display: flex;">
  	            <div class="checkbox_div">
  	             <input type="checkbox" id="checkbox" v-model="checked_option" v-bind:value="checked_str" class="cus_checkbox_percent" />
  	            </div>
  	            <div style="width: 3%"></div>

  	            <div style="margin-top: 0">
  	              <label for="checkbox" style="user-select: none; font-size: 4.4vw;">
  	                {{ checked_str }}
  	              </label>
  	            </div>

  	          </div>
  
  	          <div style="display: flex; margin-top: 1%">
  	            <div class="checkbox_div">
  	              <input type="checkbox" id="checkbox2" v-model="checked_option" v-bind:value="checked2_str" class="cus_checkbox_percent" />
  	            </div>
  	            <div style="width: 3%"></div>
  	            <div style="margin-top: 0">
  	              <label for="checkbox2" style="user-select: none; font-size: 4.4vw; ">
  	                {{ checked2_str }}
  	              </label>
  	            </div>
  	          </div>
  
  	          <div style="display: flex; margin-top: 1%;">
  	            <div class="checkbox_div">
  	              <input type="checkbox" id="checkbox3" v-model="checked_option" v-bind:value="checked3_str" class="cus_checkbox_percent" />
  	            </div>
  	            <div style="width: 3%"></div>
  	            <div style="margin-top: 0">
  	              <label for="checkbox3" style="user-select: none; font-size: 4.4vw;">
  	                {{ checked3_str }}
  	              </label>
  	            </div>
  	          </div>
  
  	          <div style="display: flex; margin-top: 1%">
  	            <div class="checkbox_div">
  	              <input type="checkbox" id="checkbox4" v-model="checked_option" v-bind:value="checked4_str" class="cus_checkbox_percent" />
  	            </div>
  	            <div style="width: 3%"></div>
  	            <div style="margin-top: 0">
  	              <label for="checkbox4" style="user-select: none; font-size: 4.4vw;">
  	                {{ checked4_str }}
  	              </label>
  	            </div>
  	          </div>
  
  	          <div style="display: flex; margin-top: 1%">
  	            <div class="checkbox_div">
  	              <input type="checkbox" id="checkbox5" v-model="checked5" class="cus_checkbox_percent" />
  	            </div>
  	            <div style="width: 3%"></div>
  	            <div style="display:flex; flex-direction: column; margin-top: 0">

  	              <label for="checkbox5" style="text-align: left; user-select: none; font-size: 4.7vw;">
  	                Other
  	              </label>

  	              <input v-model="other_option" style="border-radius: 11px; font-size: 3vw; width: 100%; height: 35px;" v-if="checked5"/>

  	            </div>
  	          </div>
  
            </div>

            <div style="width: 100%; height: 100%; background-color: none">

              <div class="display_center_x" style="width: 90%; background-color: none; margin-top: 7%">

                <div style="user-select: none; font-weight: bold; font-size: 1vw; color: rgba(0, 0, 0, 0.0)"> ENTER AJA, Just new line ha hi hu</div>

                <div class="display_center_x" style="width: 100%;">
                  <div class="form-group">
                   <input type="text"  name="name" required="required" class="cus_text" style="font-size: 5vw" v-model="firstname" />
                   <label style="font-size: 3.5vw">First Name</label>
                  </div>
                </div>

                <div class="display_center_x" style="width: 100%; margin-top: 10%;">
                  <div class="form-group">
                    <input type="text"  name="name" required="required" class="cus_text" style="font-size: 5vw" v-model="lastname" />
                    <label style="font-size: 3.5vw">Last Name</label>
                  </div>
                </div>

                <div class="display_center_x" style="width: 100%; margin-top: 10%;">
                  <div class="form-group">
                    <input type="text"  name="name" required="required" class="cus_text" style="font-size: 5vw" v-model="email"/>
                    <label style="font-size: 3.5vw">Email</label>
                  </div>
                </div>

                <div class="display_center_x" style="width: 100%; margin-top: 10%">
                  <div class="form-group">
                    <input type="tel"  name="name" required="required" class="cus_text only_num" style="font-size: 5vw" v-model="phone" />
                    <label style="font-size: 3.5vw">Phone Number</label>
                  </div>
                </div>

                <textarea v-model="message" placeholder="Your message here" style="font-family: Rubik; font-size: 4vw; width: 100%; height: 350px; border-radius: 11px; border:2px solid black; margin-top: 10%"></textarea>

                <div style="user-select: none; font-weight: bold; font-size: 1vw; color: rgba(0, 0, 0, 0.0)"> ENTER AJA, Just new line ha hi hu</div>

                <div style="display: flex;">
                  <div style="font-size: 3vw; color: red">
                  *
                  </div>
                  <div style="font-size: 3vw; color: black">
                   Must be filled in
                  </div>
                </div>

              </div>

              <div style="user-select: none; font-size: 5vw; color: red; margin-bottom: 5%;" v-if="is_show_error">
                <div style="user-select: none; font-weight: bold; font-size: 4vw; color: rgba(0, 0, 0, 0.0)"> ENTER AJA </div>
                <div style="font-family: bs_condensed;" v-if="this.firstname == ''">Please fill in first name field</div>
                <div style="font-family: bs_condensed;" v-if="this.lastname == ''">Please fill in last name field</div>
                <div style="font-family: bs_condensed;" v-if="this.phone == ''">Please fill in phone field</div>
                <div style="font-family: bs_condensed;" v-if="this.email == ''">Please fill in email field</div>
                <div style="font-family: bs_condensed;" v-if="!this.validate_email(this.email)">Your email format is incorrect</div>
                <div style="font-family: bs_condensed;" v-if="this.checked_option.length == 0">Please select one or more options from the checkbox above </div>
              </div>

              <div style="user-select: none; font-size: 1.2vw; color: green; margin-bottom: 5%" v-if="is_show_success">
                <div style="font-family: bs_condensed; user-select: none; font-weight: bold; font-size: 4vw; color: rgba(0, 0, 0, 0.0)"> ENTER AJA </div>
                  Your message was sent successfully
                </div>

                <div style="user-select: none; font-weight: bold; font-size: 4vw; color: rgba(0, 0, 0, 0.0)"> ENTER AJA </div>

                <div>
                  <img v-bind:src="btn_send" style="width: 30%; height: auto; margin-bottom: 10%" v-on:click="on_send_clicked()" />
                </div>
              </div>

            </div>

          </div>

        </div>

</template>

<script>

import jQuery from "jquery";
const $ = jQuery;
window.$ = $;

import * as platform from '@/components/platform.js'
import axios from 'axios';
import * as connection from '@/components/connection.js';

export default{
name: 'contact',
        data(){
          return{
               is_show_error: false,
               is_show_success: false,
               content_view: document.getElementById('content_view'),
               is_mobile: false,
               checked_option: [],
               checked_str: 'Use Arena Main for my app or site',
               checked2_str: 'Submit my game to Arena Main',
               checked3_str: 'Promote Arena Main on my social media channel',
               checked4_str: 'Work at Gamotions',
               checked: false,
               checked2: false,
               checked3: false,
               checked4: false,
               checked5: false,
               img_1: require('@/assets/images/contact/img1.png'),
               btn_send: require('@/assets/images/contact/btn_send.png'),
               other_option: '',
               firstname: '',
               lastname: '',
               phone: '',
               email: '',
               message: '',
          }
        },
        mounted(){
          this.content_view.scrollTop = 0; // scroll to top of page
          this.is_mobile = platform.detect_mobile();

          $(".only_num").on("keypress keyup blur",function (event) {    
              $(this).val($(this).val().replace(/[^\d].+/, ""));
              if ((event.which < 48 || event.which > 57)) {
              event.preventDefault();
              }
              });

          this.on_resize();

        },
methods: {
           on_send_clicked(){

             if(this.checked5){
               if(this.other_option != ''){
                 this.checked_option.push(this.other_option);
               }
             }

             let pass =
               this.firstname != '' &&
               this.lastname != '' &&
               this.phone != '' &&
               this.email != '' &&
               this.validate_email(this.email) &&
               this.checked_option.length > 0;

             if(pass){
              var message_with_phone = this.message + " Contact Person:"  + this.phone;

               // var obj = {
               //    firstname: this.firstname,
               //    lastname: this.lastname,
               //    phone: this.phone,
               //    email: this.email,
               //    message: this.message,
               //    intention: this.checked_option,
               // };

               var obj = {
                  first_name: this.firstname,
                  last_name: this.lastname,
                  email: this.email,
                  message: message_with_phone,
                  intention: this.checked_option,
               };

               // let stringify = JSON.stringify(obj);

               // reset if success
               this.checked_option = [];
               this.checked5 = false;
               this.is_show_error = false;

               axios(connection.method_post(connection.api_mail, obj))
                 .then( response => (
                       // console.log(response.data),
                       this.is_show_success = true
                       ))
                 .catch(error => (console.log(error)));
             }
             else{
               this.is_show_success = false;
               this.is_show_error = true;
             }

           },
           validate_email(email) 
           {
             var re = /\S+@\S+\.\S+/;
             return re.test(email);
           },
           on_resize() {
             if(window.innerWidth <= 1024){
               this.is_mobile=true;
             }
             else{
               this.is_mobile=false;
             }
           }
         },

         created() {
           window.addEventListener("resize", this.on_resize);
         },
         unmounted() {
           window.removeEventListener("resize", this.on_resize);
         },

}
</script>

<style>

input.cus_text{
width: 100%; 
height: 35px;
}

input.cus_checkbox_percent{
width: 100%; 
height: 100%;
}

.checkbox_div{
width: 35px; 
height: 35px;
}

.checkbox_div_mobile{
width: 27px; 
height: 27px;
}

.form-group{position:relative; text-align: left;}
/* .form-group input{border-radius: 11px; position:relative;z-index:9;background:transparent;border:2px solid #cbcbcb;padding: 5px;} */
.form-group input{border-radius: 11px; padding: 5px;}
.form-group label{position:absolute;left:5px;top:5px;z-index:1;color:gray;}
.form-group label::after{content:"*";color:red;}
input[required]:valid + label{display: none;}


::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
/* color: #bababa; */
color: gray;
opacity: 1; /* Firefox */
}

</style>

