<template style="height: 100%">

<!-- 
     kevin haryo kuncoro
     kevinhyokun91@gmail.com
     kevin.haryo@timobile.tech
  -->

<img v-bind:src="pres1" class="pres" />

<!-- 
<div v-if="!is_mobile">
  <img v-bind:src="pres1" class="pres" />
</div>
<div v-else>
  <div style="display: flex; flex-direction: column; flex-wrap: wrap;" class="pres">
    <img v-bind:src="pres1_m" class="pres" />
    <img v-bind:src="pres1_m_b" class="pres" />
  </div>
</div>
-->

<!-- section 2 -->

<div style="width: 100%; height: auto; background-color:#abbcd6">
  <div class="pres" style="display: flex;" v-if="!is_mobile">
    
    <div style="width: 50%">

      <router-link to="/arenamain" style="text-decoration: none;">
        <img v-bind:src="pres2_m" class="pres" />
      </router-link>

    </div>
    <div style="width: 50%">
      
      <div style="height: 100%">
        
        <div class="display_center_x" style="width: 80%; height: 80%; background-color: none;">
  	
  	<div style="user-select: none; font-family: bs_condensed; font-size: 2.0vw; color: #45466b; margin-top: 20%">
  	  Increase your engagement, retention, and revenue with our secret ingredient:
  	</div>
  	
  	<div style="user-select: none;  font-family: bs_condensed; font-weight: bold; font-size: 4.0vw; color: #120f3a; margin-top: 6%;">
  	  The Gaming Button for your App & Site
  	</div>
  	
  	<ButtonComponent v-bind:width="65" style="margin-top: 20%"/>
  	
        </div>
        
        
      </div>
    </div>
    
  </div>
  <div style="display: flex; flex-direction: column; flex-wrap: wrap;"  v-else> <!-- mobile -->
  
    <router-link to="/arenamain" style="text-decoration: none;">
      <img v-bind:src="pres2_m" class="pres" />
    </router-link>

    <!-- <img v-bind:src="pres2_m_b" class="pres" /> -->
    
    <div style="width: 100%">
      
      <div class="display_center_x" style="width: 80%; background-color: none;">
        <div style="user-select: none; font-family: bs_condensed; font-size: 6.0vw; color: #45466b">
  	Increase your engagement, retention, and revenue with our secret ingredient:
        </div>
        
        
        <!-- <div style="user-select: none; font-weight: bold; font-size: 9.0vw; color: #232328"> -->
        <div style="user-select: none; font-family: bs_condensed; font-weight: bold; font-size: 13.0vw; color: #120f3a; margin-top: 10%">
  	The Gaming Button for your App & Site
        </div>
      </div>
      
      <ButtonComponent v-bind:width="90" style="margin-top: 10%; margin-bottom: 10%"/>
      
    </div>
    
  </div>

</div>


<!-- section 3 -->
<!-- <div style="width: 100%; background-color: #869ac4"> -->
<div v-bind:style="{width: '100%', backgroundColor: '#869ac4',backgroundImage: 'url(' + pattern2 + ')', backgroundSize: '652px 582px' }">
<!-- <div v-bind:style="{width: '100%', backgroundColor: '#869ac4',backgroundImage: 'url(' + pattern2 + ')', backgroundSize: 'contain' }"> -->
  <div v-if="!is_mobile">
    
    <div style="user-select: none; font-weight: bold; font-size: 2.7vw; color: rgba(0, 0, 0, 0.0)"> ENTER AJA, Just new line ha hi hu</div>
    
    <div style="width: 100%; display: flex;">

      <div style="width: 50%;">
	<img v-bind:src="car_img" class="pres" /> 
      </div>

      <div style="width: 50%;">

        <div class="display_center" style="width: 100%; height: 50%; display: grid;">

          <div class="our_games_txt" style="font-family: bs_condensed; font-size: 5vw;">
          OUR GAMES
          </div>

          <div>
            <router-link style="text-decoration: none;" to="/games">
            <img v-bind:src="btn_games" style="width: 30%; height: auto;" />
            </router-link>
          </div>

        </div>

      </div>

    </div>
    
  </div>
  <div style="display: flex; flex-direction: column;" v-else> <!-- mobile -->
    
    <div>
      <img v-bind:src="car_img" class="pres" /> 
    </div>
    <div>
      
      <div style="user-select: none; font-weight: bold; font-size: 5vw; color: rgba(0, 0, 0, 0.0)"> ENTER AJA, Just new line ha hi hu</div>
      
      <div class="our_games_txt" style="font-family: bs_condensed; font-size: 15vw;">
	OUR GAMES
      </div>
      
      <div style="user-select: none; font-weight: bold; font-size: 5vw; color: rgba(0, 0, 0, 0.0)"> ENTER AJA, Just new line ha hi hu</div>
      
      <router-link style="text-decoration: none;" to="/games">
	<img v-bind:src="btn_games" style="width: 60%; height: auto;" />
      </router-link>

    </div>
    
    <div style="user-select: none; font-weight: bold; font-size: 13vw; color: rgba(0, 0, 0, 0.0)"> ENTER AJA, Just new line ha hi hu</div>
    
  </div>
</div>


<!-- section 4 -->

<div style="width: 100%; height: auto; background-color: white">
  <div v-if="!is_mobile" style="width: 100%; height: 100%" >
    <div style="width: 100%; height: 100%; display: flex;">
      <div style="width: 50%; height:100%; background-color: none;">
        <div class="display_center_x" style="font-family: bs_condensed; text-align: center; color: #45466b; width: 80%; height: auto; font-size: 3vw;  background-color: none; user-select: none; margin-top: 10%">
  	  ACHIEVE YOUR DREAM AND SUCCESS WITH
        </div>

        <div style="width: 100%;">
          <img v-bind:src="logo_blue" style="width: 75%; height: auto; margin-top: 10%" />
        </div>

        <router-link style="text-decoration: none;" to="/careers">
          <img v-bind:src="btn_career" style="width: 25%; height: auto; margin-top: 10%; margin-bottom: 10%"/>
        </router-link>

      </div>
      <div style="width: 50%; height:100%; background-color: none;">
          <img v-bind:src="career_img" style="width: 75%; height: auto; margin-top: 10%" />
      </div>
    </div>
  </div>
  <div v-else style="width: 100%; height: 100%">
    <div style="width: 100%; height: 100%; display: flex; flex-direction: column">
      <div class="display_center_x" style="width: 90%; height:100%; background-color: none;">

        <div class="display_center_x" style="font-family: bs_condensed; text-align: center; color: #45466b; width: 100%; height: auto; font-size: 6vw;  background-color: none; user-select: none; margin-top: 10%">
  	  ACHIEVE YOUR DREAM AND SUCCESS WITH
        </div>

        <div style="width: 100%;">
          <img v-bind:src="logo_blue" style="width: 90%; height: auto; margin-top: 10%" />
        </div>

        <router-link style="text-decoration: none;" to="/careers">
          <img v-bind:src="btn_career" style="width: 65%; height: auto; margin-top: 10%;"/>
        </router-link>

      </div>
      <div class="display_center_x" style="width: 80%; height:100%; background-color: none;">
          <img v-bind:src="career_img" style="width: 100%; height: auto; margin-top: 10%; margin-bottom: 20%" />
      </div>
    </div>
  </div>
</div>

</template>

<script>
import * as platform from '@/components/platform.js'
import ButtonComponent from '@/components/ButtonComponent.vue'

export default{
    name: 'home',
    components: {
	ButtonComponent,
    },
    data(){
	return{
	    arenamain_url: "https://arenamain.id",
	    content_view: document.getElementById('content_view'),
	    is_mobile: false,
	    pres1: require('@/assets/images/homepage/pres_1.png'),
	    pres2_m: require('@/assets/images/homepage_m/img_2_.png'),
	    car_img: require('@/assets/images/homepage/car_.png'),
	    btn_career: require('@/assets/images/btn_career_.png'),
	    btn_games: require('@/assets/images/btn_games_.png'),
	    pattern2: require('@/assets/images/pattern2b.png'),
	    logo_blue: require('@/assets/images/logo_blue.png'),
	    career_img: require('@/assets/images/homepage_m/career.png'),
	}
    },
    mounted(){
	this.content_view.scrollTop = 0; // scroll to top of page
	this.is_mobile = platform.detect_mobile();
        this.on_resize();
    },
    updated(){
    },
    created() {
      window.addEventListener("resize", this.on_resize);
    },
    unmounted() {
      window.removeEventListener("resize", this.on_resize);
    },
    methods: {
    on_resize() {
      if(window.innerWidth <= 1024){
        this.is_mobile=true;
      }
      else{
        this.is_mobile=false;
      }
    }
}

}
</script>
<style>
.our_games_txt{
  color: white;
  font-family: bs;
  user-select: none; 
  font-weight: bold; 
}
</style>
