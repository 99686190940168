/*
  kevin haryo kuncoro
  kevinhyokun91@gmail.com
  kevin.haryo@timobile.tech
*/

var qs = require('qs');
import axios from 'axios';

export var conn_stg = "https://arenamain.id/api/v1/";
export var conn_prod = "https://arenamain.id/api/v1/";

export function get_api(){

    if(location.hostname == "localhost" ||
       location.hostname == "stgapi.timobile.tech"){
	return conn_stg; 
    }

    return conn_prod;
}

export var api_mail = get_api() + "cms/mailer/contact-us";

export function method_post(api, param_obj = null){

// console.log(param_obj);
// console.log(qs.stringify(param_obj) );
// console.log(JSON.stringify(param_obj));
// console.log(api_mail);
// console.log(api);

    // return {
    //     method: 'post',
    //     url: api,
    //     headers: { 
    //         'Content-Type': 'application/x-www-form-urlencoded'
    //     },
    //     data : param_obj
    // }

    return {
	method: 'post',
	url: api,
	data : param_obj
    }
}
