<template>

<!-- 
     kevin haryo kuncoro
     kevinhyokun91@gmail.com
     kevin.haryo@timobile.tech
  -->

<div class="header">
  
  <!-- navbar -->
  <div v-bind:style="{position: 'absolute', top: 0, left: 0, zIndex: navbar_z_index, width: window_width + 'px', height: window_height + 'px'}" v-show="is_show_navbar">
    
    <div v-bind:style="{position: 'absolute', top: 0, left: 0, zIndex: navbar_z_index, width: window_width + 'px', height: window_height + 'px', backgroundColor: 'black', opacity: 0.5}" v-on:click="navbar_toggle">
    </div>
    
    <div v-bind:style="{position: 'absolute', top: 0, left: 0, zIndex: navbar_z_index, width: 250 + 'px', height: window_height + 'px', backgroundColor: '#2D2A3B', overflow: 'auto'}">
      
      <!-- <div class="display_center_x" style="margin-top: 9px; width: 30%" v-on:click="navbar_toggle"> 
	<object class="clickable_svg" v-bind:data="close_icon" type="image/svg+xml" style="width:16px; height: 16px; margin-bottom: 20px;"></object> -->

      <div style="margin-left: 157px; margin-top: 9px; width: 30%" v-on:click="navbar_toggle">
	<object class="clickable_svg" v-bind:data="close_icon" type="image/svg+xml" style="width:16px; height: 16px; margin-bottom: 20px;"></object>

      </div>
      <!-- <p class="display_center_x menu_item" style="margin-left: 0" v-on:click="navbar_toggle" >X</p> -->
      
      <ul class="menu_list" style=" padding-left: 10px; padding-right: 10px;">

	<!-- <router-link to="/" style="text-decoration: none;" v-on:click="navbar_toggle"><li class="menu_item" >Beranda</li></router-link> --> 

	<!-- 
	<router-link to="/" style="text-decoration: none;" v-on:click="navbar_toggle">
	  <div  style="margin-bottom: 40px;">
	  </div>
	</router-link> 
	-->
	
	<!--
	<hr class="gray" style="margin-bottom: 40px; margin-left: 20px; margin-right: 20px">

	<router-link style="text-decoration: none;" v-on:click="navbar_toggle" to="/aboutus"><li class="menu_item">Tentang Kami</li></router-link>
	<router-link style="text-decoration: none;" v-on:click="navbar_toggle" to="/contactus"><li class="menu_item">Kontak Kami</li></router-link>
	-->

	<!-- 
	<hr class="gray" style="margin-bottom: 40px; margin-left: 20px; margin-right: 20px">
	-->
	<router-link style="text-decoration: none;" v-on:click="navbar_toggle" to="/"> <li class="menu_item">Home</li></router-link>
	<router-link style="text-decoration: none;" v-on:click="navbar_toggle" to="/arenamain"> <li class="menu_item">Arena Main</li></router-link>
	<router-link style="text-decoration: none;" v-on:click="navbar_toggle" to="/games"> <li class="menu_item">Games</li></router-link>
	<router-link style="text-decoration: none;" v-on:click="navbar_toggle" to="/careers"> <li class="menu_item">Careers</li></router-link>
	<router-link style="text-decoration: none;" v-on:click="navbar_toggle" to="/contact"> <li class="menu_item">Contact</li></router-link>
	<router-link style="text-decoration: none;" v-on:click="navbar_toggle" to="/about"> <li class="menu_item">About</li></router-link>

      </ul>
    </div>

  </div>
  <!-- navbar -->
  
  <div style="position: absolute; left: 0; right: 0; margin-right: auto; margin-left: auto; height: 100%;">

    <!-- 
	 parent: absolute position
	 child: relative position
      -->
    
    <div class="display_center" style="height: 70%; margin-left: 10px; position: relative;">

      <router-link to="/" style="text-decoration: none;">
	<div class="display_center_x" style="width: 200px; height: 100%;">
	  <img v-bind:src="logo_img" class="display_center" style="width: 85%; height: auto;" />
	</div>
      </router-link>

    </div>
    
  </div>

  <div v-on:click="navbar_toggle" class="display_center" style="float: left; color: white; margin-left: 10px; width: 12%; height: 100%">
    <object class="clickable_svg display_center" v-bind:data="navbar_icon" type="image/svg+xml" style="width:32px; height: 32px; margin-left: 5px; "></object>
  </div>
  
</div>
</template>

<script>
import './style.css';

var is_tes = false;

export default{
    name: 'header',
    data(){
	return {
	    is_show_navbar: false,
	    navbar_z_index: 10,
	    logo_img: require('@/assets/images/logo.png'),
	    navbar_icon: require('@/assets/svg/navbar.svg'),
	    drop_down_icon: require('@/assets/svg/drop_down.svg'),
	    drop_up_icon: require('@/assets/svg/drop_up.svg'),
	    close_icon: require('@/assets/svg/close.svg'),
	    // is_show_category_sub_menu: false,
	    is_show_category_sub_menu: true,
	    // home_icon: require('@/assets/images/home_icon.png'),
	    category_menu_item: null,
	    component: this,
	}
    },
    props:{
	game_category_item: Array,
	window_width: Number,
	window_height: Number,
    },
    created(){
    },
    beforeUpdate(){
    },
    updated(){
    },
    mounted(){
	this.category_menu_item = document.querySelector('.category_menu_item');
    },
    unmounted(){
    },
    methods: {
	navbar_toggle(){
	    // if(this.is_show_category_sub_menu){ this.category_menu_toggle(); } 
	    this.is_show_category_sub_menu = true; // remove this and uncomment line above when you need to toggle sub menu 

	    this.is_show_navbar = !this.is_show_navbar;

	},
	category_menu_toggle(){
	    this.is_show_category_sub_menu = !this.is_show_category_sub_menu;
	},
	route_go(){
	    this.$router.go();
	},
	clear_window(){
	    this.is_show_category_sub_menu = false; 
	    this.is_show_navbar = false; 
	},

    },
    
}
</script>

<style>
.text_decoration_none{
    text-decoration: none;
}

hr.gray{
    border: 1px solid #3a3846;
    border-radius: 5px;
    /* color: #3a3846; */
    color: #3a3846;
}

.clickable_svg{
    pointer-events: none;
}
</style>
