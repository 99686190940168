<template>
<!-- 
     kevin haryo kuncoro
     kevinhyokun91@gmail.com
     kevin.haryo@timobile.tech
  -->

  <div class="display_center_x" v-bind:style="{width: '100%', height: '100%'}">

  <img v-bind:src="img" class="pres arenamain_touchpoint" @click="invoke_portal"/>

    <div style="height: 10%;"/>

    <div v-if="is_show_button">

      <div style="position:relative;">
      <img v-bind:src="btn_img" style="width: 65%; height: auto" @click="invoke_portal"/>
          <div v-bind:style="{position: 'absolute', userSelect: 'none', fontFamily: 'bs_condensed', fontWeight: 'bold', fontSize: title_font_size + 'vw', color: 'white'}" class="display_center display_center_x_absolute arenamain_touchpoint" @click="invoke_portal">
            {{ title }}
          </div>
      </div>

    </div>
    <div v-else>
      <!-- 
      <div v-bind:style="{marginTop: '20px', userSelect: 'none', fontFamily: 'bs', fontWeight: 'bold', fontSize: title_font_size + 'vw'}">
        {{ title }}
      </div>
      -->
    </div>


  </div>

</template>

<script>
export default{
    name: 'GameItem',
    data(){
	return{
	    img: require('@/assets/images/games/' + this.img_file),
	    btn_img: require('@/assets/images/btn_untitled.png'),
	}
    },
    methods:{
      invoke_portal: function(){window.show_arenamain_anchor(this.ins_index);}
    },
    props: {
	img_file: String,
	title: String,
        url: String,
        ins_index: Number,
        is_show_button: Number,
	title_font_size: Number,
    },
}
</script>

<style>
</style>

